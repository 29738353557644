<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(INSURANCE_INFORMATION_FORM_TITLE) }}
      </h3>
    </div>
    <div class="pl-1">
      <div
        v-for="field in ['policy_number', 'amount_insured', 'deductible']"
        :key="field"
      >
        <div
          class="d-flex"
          style="gap: 12px"
        >
          <b
            class="d-inline-block"
            style="min-width: 150px"
          >
            {{ $t(insuranceInformationFields[field].label) }}
          </b>
          <span>{{ $t(insuranceInformation[field]) }}</span>
        </div>
      </div>
    </div>
    <div class="pl-1 mt-1">
      <div
        v-for="field in ['coverage_start_date', 'coverage_end_date']"
        :key="field"
      >
        <div
          class="d-flex"
          style="gap: 12px"
        >
          <b
            class="d-inline-block"
            style="min-width: 150px"
          >
            {{ $t(insuranceInformationFields[field].label) }}
          </b>
          <span>{{ insuranceInformation[field] ? format(insuranceInformation[field], 'MMMM dd, yyyy') : '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import { isFuture, isToday, format } from 'date-fns'
import config from '../../create/config'

export default {
  name: 'InsuranceInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    insuranceInformation() {
      return this.$store.state[this.MODULE_NAME].insuranceInformationForm
    },
    customerId() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm.customer_id
    },
    isTodayOrFuture() {
      const endDate = this.insuranceInformation?.coverage_end_date
      return endDate ? isToday(endDate) ? false : !isFuture(endDate) : false
    },
  },
  methods: {

  },
  setup() {
    const {
      MODULE_NAME,
      insuranceInformationFields,
      INSURANCE_INFORMATION_FORM_TITLE,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      insuranceInformationFields,
      INSURANCE_INFORMATION_FORM_TITLE,
      format,
    }
  },
}
</script>
<style lang="scss">
.verification-data-pickers {
  &__wrapper {

    label {
      display: none !important;
    }

    & .form-group:first-child .form-control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    & .form-group:last-child .form-control {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left: none;
    }
  }

}
.eyeIcon{
  position: absolute;
  top: 30px;
  right: 5px;
}
</style>
