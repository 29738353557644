<template>
  <div class="Tool">
    <b-tooltip
      ref="tooltip"
      style="max-width: 300px;"
      custom-class="parentToolTip"
      :placement="placement"
      :target="target"
      triggers="click"
    >
      <!--      triggers="click"-->
      <slot />
    </b-tooltip>
  </div>
</template>
<script>

import { BTooltip } from 'bootstrap-vue'

export default {
  name: 'LToolTip',
  components: { BTooltip },
  props: {
    target: {
      type: String,
      request: false,
      default: '',
    },
    placement: {
      type: String,
      request: true,
      default: 'top',
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    show() {
      this.$refs.tooltip.$emit('open')
      this.$emit('showBTooltip')
    },
    hide() {
      this.$refs.tooltip.$emit('close')
      this.$emit('hideBTooltip')
    },
    handleClickOutside(event) {
      if (!this.$refs.tooltip.$el.contains(event.target)) {
        this.hide()
      }
    },
  },
}
</script>
  <style  scoped>
  .parentToolTip{
    width: auto;
    min-width: 371px!important;
   }
  .parentToolTip > div{
    width: auto !important;
    max-width: unset !important;
    height: 178px;
    opacity: 1;
    padding: 20px;
  }
  .info-title{
    font-size: 22px;
    text-align: start;
    color: #ffffff;
  }
  .info-continer-left{
    width: 30%;
    text-align: start;
    font-size: 16px;
    p{
      margin-bottom: 0.5rem;
    }
  }
  .info-continer-right{
    text-align: start;
    width: 70%;
    font-size: 16px;
    p{
      margin-bottom: 0.5rem;
    }
  }
  </style>
