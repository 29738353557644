<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(ORDER_INFORMATION_FORM_TITLE) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <div class="d-flex justify-content-between align-items-center">
          <label
            class="font-weight-bolder ml-1 mb-0"
            for="account"
          >{{ $t('Account') }}</label>
          <select-account
            class="ml-2 flex-grow-1"
            :auto-suggest-table-columns="autoSuggestTableColumnsAccount"
            :module-name="MODULE_NAME"
            :customer-i-d="orderInformationCustomerID"
            :module-name-orders="MODULE_NAME_ORDERS"
            label=""
            :is-search-button="true"
            :is-label="false"
            style="max-width: 500px"
            :default-search-query="searchQuery"
            @selectHandler="selectHandler"
            @onInput="onInput"
          />
        </div>
        <div class="mt-2 d-flex  justify-content-between align-items-center">
          <label
            class="font-weight-bolder ml-1 mb-0"
            for="receive_method"
          >{{ $t('Received Via: ') }}</label>
          <component
            :is="orderInformationFields[field].type"
            v-for="field in ['receive_method']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="customerInformation[field]"
            class="ml-2 flex-grow-1"
            style="max-width: 500px"
            v-bind="getProps(field, orderInformationFields)"
          />
        </div>
      </b-col>
      <!--      ADD MODULE_NAME_ORDERS AND CHANGE MODULE_NAME VALUE TO MODULE_NAME-->
      <b-col md="6">
        <div class="d-flex align-items-center">
          <label
            class="font-weight-bolder ml-1  mb-0"
            for="po_number"
          >{{ $t('Reference') }}</label>
          <component
            :is="orderInformationFields[field].type"
            v-for="field in ['po_number']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="customerInformation[field]"
            class="m-0 ml-1 flex-grow-1"
            :disable-label="true"
            v-bind="getProps(field, orderInformationFields)"
          />
        </div>
        <div class="d-flex align-items-center mt-1">
          <label
            class="font-weight-bolder ml-1  mb-0"
            for="po_number"
          >{{ $t('Pick Up: ') }}</label>
          <div class="d-flex justify-content-around flex-grow-1">
            <component
              :is="eventInformationFields['event_information_dispatch_date'].type"
              v-model="eventInformation['event_information_dispatch_date']"
              v-b-tooltip.noninteractive.hover
              :disable-label="true"
              style="max-width: 150px"
              v-bind="getProps('event_information_dispatch_date', eventInformationFields, new Date(),endDateOfInsurance)"
            />
            <b-form-input
              id="event_information_rental_start_dispatch_time"
              v-model="eventInformation['event_information_rental_start_dispatch_time']"
              type="time"
              style="max-width: 150px"
            />
            <component
              :is="customerInformationFields['dispatch_contact_id'].type"
              ref="dispatch_contact_ref"
              v-model="customerInformation['dispatch_contact_id']"
              :disable-label="true"
              style="width:  150px"
              v-bind="
                getProps(
                  'dispatch_contact_id',
                  customerInformationFields,
                  true,
                  'dispatch_contact_id',
                  getParentValue
                )
              "
              @input="onDispatchReturnContactChange($event, 'dispatch_contact_id')"
            />
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <label
            class="font-weight-bolder ml-1  mb-0"
            for="po_number"
          >{{ $t('Return: ') }}</label>
          <div class="d-flex justify-content-around flex-grow-1">
            <component
              :is="eventInformationFields['event_information_rental_end'].type"
              ref="event_information_rental_end_ref"
              v-model="eventInformation['event_information_rental_end']"
              v-b-tooltip.noninteractive.hover
              :disable-label="true"
              style="max-width: 150px"
              v-bind="getProps('event_information_rental_end', eventInformationFields, this.eventInformation.event_information_rental_start_dispatch,endDateOfInsurance)"
            />
            <b-form-input
              id="event_information_rental_end_time"
              v-model="eventInformation['event_information_rental_end_time']"
              type="time"
              style="max-width: 150px"
            />
            <component
              :is="dispatchAndReturnFields['return_contact_id'].type"
              ref="dispatch_contact_id_ref"
              v-model="dispatchAndReturn['return_contact_id']"
              :disable-label="true"
              style="width: 150px"
              v-bind="getProps('return_contact_id', dispatchAndReturnFields, true, 'return_contact_id', getParentValue)"
              @input="onDispatchReturnContactChange($event, 'return_contact_id')"
            />
          </div>
        </div>
        <!--        :is-required-field="requiresPurchaseOrderReq"-->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BFormInput,
} from 'bootstrap-vue'
import { getValueFromGivenObjectByKey, getProps } from '@core/utils/utils'
import config from '../../create/config'
import SelectAccount from '../../../../components/use-as-global/SelectAccount.vue'

export default {
  name: 'OrderInformation',
  components: {
    BFormInput,
    BRow,
    BCol,
    SelectAccount,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    // customId: {
    //   type: [String, Number],
    //   default: 0,
    // },er
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  computed: {
    order(){
      return this.$store.state[this.MODULE_NAME].order
    },
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    orderInformationCustomerID() {
      return this.orderInformation.customer_id
    },
    isOrderInformationFormRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isOrderInformationFormRequiredFieldsFilled
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    paymentTermsList() {
      return this.$store.state.listModule.paymentTermList
    },
    endDateOfInsurance() {
      return this.$store.state[this.MODULE_NAME].insuranceInformationForm.coverage_end_date
    },
  },
  watch: {
    order(val) {
      if (val) {
        this.searchQuery = val.customer?.cust_name_dba
      }
    },
    searchQuery(val) {
      if (!val) {
        this.resetSelectedAccount()
      }
    },
  },
  methods: {
    getParentValue() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm?.customer_id
    },
    onDispatchReturnContactChange(contact, field) {
      if (field === this.RETURN_CONTACT_FIELD_NAME) {
        this.customerInformation.return_phone = getValueFromGivenObjectByKey(contact, 'cell_no')
        this.customerInformation.return_email = getValueFromGivenObjectByKey(contact, 'email')
      }
      if (field === this.DISPATCH_CONTACT_FIELD_NAME) {
        this.customerInformation.dispatch_phone = getValueFromGivenObjectByKey(contact, 'cell_no')
        this.customerInformation.dispatch_email = getValueFromGivenObjectByKey(contact, 'email')
      }
    },
    selectHandler(item) {
      const {
        id,
        customer_detail,
        default_contact, // todo: cell bn email backdan kemayabpti
        default_address,
        insurance_policy,
        customer_rules,
      } = item

      const price_tier = item.payment_tier

      const policy_number = insurance_policy?.policy_number
      const amount_insured = insurance_policy?.amount_insured
      const deductible = insurance_policy?.deductible
      const coverage_start_date = insurance_policy?.coverage_start_date
      const coverage_end_date = insurance_policy?.coverage_end_date
      const customer_rules_filter = customer_rules?.filter(k => k.name === 'Requires Purchase Order')?.[0]?.id === 1
      this.$store.commit(`${this.MODULE_NAME}/SET_INSURANCE_INFORMATION_FORM`, {
        policy_number,
        amount_insured,
        deductible,
        coverage_end_date: new Date(coverage_end_date),
        coverage_start_date: new Date(coverage_start_date),
      })

      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        billing_phone: '-',
        billing_email: '-',
        price_tier: price_tier?.label ?? '',
        customer_notes: getValueFromGivenObjectByKey(customer_detail, 'comments'),
        customer_discount: customer_detail?.discount ?? 0,
        default_address,
        deposit_requirement: Number(item.deposit_requirement),
        billing_address: default_address ? `${getValueFromGivenObjectByKey(default_address, 'street', '—')}, ${getValueFromGivenObjectByKey(default_address, 'line2', '—')}, ${getValueFromGivenObjectByKey(default_address, 'city', '—')}, ${getValueFromGivenObjectByKey(default_address, 'state.code', '—')} ${getValueFromGivenObjectByKey(default_address, 'zip', '—')}, ${getValueFromGivenObjectByKey(default_address, 'country.three_letter', '—')}` : 'Customer Missing Address',
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_PURCHASE_ORDER`, {
        requiresPurchaseOrder: customer_rules_filter,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDERS_INFORMATION_FORM`, {
        ...this.orderInformation,
        customer_id: id,
      })
      this.setAccessForAppearingForms()
    },
    onInput(searchQuery) {
      this.searchQuery = searchQuery
    },
    setAccessForAppearingForms() {
      const access = !!this.orderInformation.customer_id && this.isOrderInformationFormRequiredFieldsFilled
      this.$store.commit(`${this.MODULE_NAME}/SET_IS_SETTLED_CUSTOMER`, access)
    },
    resetSelectedAccount() {
      this.orderInformation.customer_id = null
      this.setAccessForAppearingForms()
    },
    checkIsFilledRequiredFields() {
      const exists = Object.values(this.orderInformation).includes(true)
      if (!exists) {
        this.$store.commit(`${this.MODULE_NAME}/SET_IS_ORDER_INFORMATION_FORM_REQUIRED_FIELDS_FILLED`, false)
        this.setAccessForAppearingForms()
        return
      }

      this.checkIsAllRequiredFieldsFilled(
        this.orderInformation,
        // this.x,
        this,
        'SET_IS_ORDER_INFORMATION_FORM_REQUIRED_FIELDS_FILLED',
      )
      this.setAccessForAppearingForms()
    },
  },
  setup() {
    const {
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      orderInformationFields,
      eventInformationFields,
      customerInformationFields,
      dispatchAndReturnFields,
      ORDER_INFORMATION_FORM_TITLE,
      checkIsAllRequiredFieldsFilled,
      // orderInformationRequiredFields,
      autoSuggestTableColumnsAccount,
    } = config()
    const DISPATCH_METHOD_FIELD_NAME = 'dispatch_method'
    const RETURN_CONTACT_FIELD_NAME = 'return_contact_id'
    const DISPATCH_CONTACT_FIELD_NAME = 'dispatch_contact_id'
    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      orderInformationFields,
      eventInformationFields,
      customerInformationFields,
      dispatchAndReturnFields,
      ORDER_INFORMATION_FORM_TITLE,
      autoSuggestTableColumnsAccount,
      // orderInformationRequiredFields,
      DISPATCH_METHOD_FIELD_NAME,
      RETURN_CONTACT_FIELD_NAME,
      DISPATCH_CONTACT_FIELD_NAME,
      checkIsAllRequiredFieldsFilled,
    }
  },
}
</script>
