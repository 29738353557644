var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c(_vm.customerInformationFields['billing_contact_id'].type,_vm._b({ref:"billing_contact_ref",tag:"component",staticClass:"flex-grow-1",on:{"input":function($event){return _vm.onChangeBillingContact($event)}},model:{value:(_vm.customerInformation['billing_contact_id']),callback:function ($$v) {_vm.$set(_vm.customerInformation, 'billing_contact_id', $$v)},expression:"customerInformation['billing_contact_id']"}},'component',
            _vm.getProps(
              'billing_contact_id',
              _vm.customerInformationFields,
              true,
              'billing_contact_id',
              _vm.getParentValue
            )
          ,false)),_c('div',{staticClass:"ml-2"},[_c('button-dashed',{staticClass:"btnBorderRevert position-relative",staticStyle:{"top":"6px"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LAddButtonIcon","size":"32"},on:{"click":function($event){return _vm.gotoNewPage("/settings/users/create", $event, '_blank')}}})],1)],1)],1),_c('div',{staticClass:"pl-1"},_vm._l((['billing_phone', 'billing_email', 'billing_address']),function(field){return _c('div',{key:field},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('b',{staticClass:"d-inline-block",staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.customerInformationFields[field].label))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.customerInformation[field])))])])])}),0),_c('insurance-information')],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex  flex-column justify-content-between h-100"},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.ORDER_RULES_FORM_TITLE))+" ")]),_c('div',[_c(_vm.customerInformationFields['order_rules_required_security_deposit']
                    .type,_vm._b({ref:"order_rules_required_security_deposit_ref",tag:"component",model:{value:(_vm.customerInformation['order_rules_required_security_deposit']),callback:function ($$v) {_vm.$set(_vm.customerInformation, 'order_rules_required_security_deposit', $$v)},expression:"customerInformation['order_rules_required_security_deposit']"}},'component',
                  _vm.getProps(
                    'order_rules_required_security_deposit',
                    _vm.customerInformationFields
                  )
                ,false))],1)]),_c('div',{staticClass:"pl-1"},_vm._l((['price_tier', 'customer_notes']),function(field){return _c('div',{key:field},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('b',{staticClass:"d-inline-block",staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.customerInformationFields[field].label))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.customerInformation[field])))])])])}),0)]),_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.DISPATCH_FORM_TITLE))+" ")]),_c('div',[_c(_vm.customerInformationFields['return_contact_is_different']
                    .type,_vm._b({ref:"return_contact_is_different_ref",tag:"component",model:{value:(_vm.customerInformation['return_contact_is_different']),callback:function ($$v) {_vm.$set(_vm.customerInformation, 'return_contact_is_different', $$v)},expression:"customerInformation['return_contact_is_different']"}},'component',
                  _vm.getProps(
                    'return_contact_is_different',
                    _vm.customerInformationFields
                  )
                ,false))],1)]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[_c(_vm.customerInformationFields['dispatch_contact_id'].type,_vm._b({ref:"dispatch_contact_ref",tag:"component",staticClass:"flex-grow-1",on:{"input":function($event){return _vm.onDispatchReturnContactChange($event, 'dispatch_contact_id')}},model:{value:(_vm.customerInformation['dispatch_contact_id']),callback:function ($$v) {_vm.$set(_vm.customerInformation, 'dispatch_contact_id', $$v)},expression:"customerInformation['dispatch_contact_id']"}},'component',
                _vm.getProps(
                  'dispatch_contact_id',
                  _vm.customerInformationFields,
                  true,
                  'dispatch_contact_id',
                  _vm.getParentValue
                )
              ,false)),_c('button-dashed',{staticClass:"btnBorderRevert position-relative opacity-50",staticStyle:{"top":"6px"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LAddButtonIcon","size":"32"},on:{"click":function($event){return _vm.gotoNewPage(("/customers/" + _vm.orderInformationFormCustomerID + "/contacts"), $event, '_blank')}}})],1)],1),_c('div',{staticClass:"pl-1"},_vm._l((['dispatch_phone', 'dispatch_email']),function(field){return _c('div',{key:field},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('b',{staticClass:"d-inline-block",staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.customerInformationFields[field].label))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.customerInformation[field])))])])])}),0)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }