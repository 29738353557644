<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(CREATE_PAGE_TITLE) }}
      </h3>
    </portal>
    <create-form
      ref="create-form"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line import/no-cycle
import config from '../config'

// Components
import CreateForm from './Form.vue'

export default {
  name: 'RentalSalesCreate',
  components: {
    CreateForm,
  },
  computed: {
    ...mapGetters('rental-sales', ['getTotalDataOfForms']),
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['create-form']) {
      this.$refs['create-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    this.$store.dispatch('listModule/attachmentCategoryListAssets')
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_RENTAL_SALES_FORM_CLONE`, this.getTotalDataOfForms)
  },
  setup() {
    const { CREATE_PAGE_TITLE, MODULE_NAME_CLONE } = config()

    return {
      MODULE_NAME_CLONE,
      CREATE_PAGE_TITLE,
    }
  },
}
</script>
