<template>
  <div>
    <span class="font-weight-bolder">{{ $t("Attachments") }}</span>
    <div class="l-file-upload mb-1">
      <div class="d-flex justify-content-end mb-1">
        <feather-icon
          style="min-width: 37px; min-height: 37px"
          icon="LAddButtonIcon"
          size="24"
          class="cursor-pointer feather-add-icon"
          @click="$refs.refLFileUploadInput.click()"
        />
      </div>
      <!-- ===================================== -->
      <div class="upload-file">
        <div class="">
          <input
            v-show="false"
            ref="refLFileUploadInput"
            :accept="formattedAcceptProps"
            type="file"
            @input="fileInputChanged"
          >
        </div>
      </div>
      <div
        class="border mb-1"
        style="border-radius: 5px"
      >
        <div class="">
          <div
            v-if="isLoading"
            class="d-flex justify-content-center my-2"
          >
            <b-spinner />
          </div>
          <div v-if="!isLoading">
            <div
              v-if="!customerInformationAttachments().length"
              class="text-center upload-file__files-list-no-data border mb-md-1"
            >
              {{ $t('None Found') }}
            </div>
            <b-table-simple
              v-else
              :class="`upload-attachment-table ${isEditable ? 'upload-attachment-table--disabled': ''}`"
            >
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="({label, thStyle}, idx) in uploadAttachmentTableCol"
                    :key="idx"
                    :style="thStyle"
                  >
                    {{ label }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(file, index) in customerInformationAttachments()"
                  :key="index"
                >
                  <b-th>
                    <span
                      v-b-tooltip.noninteractive.hover
                      class="file-name"
                      :title="file.id ? file.name : file.file.name"
                    >
                      {{ file.id ? file.name : file.file.name }}
                    </span>
                  </b-th>
                  <b-th>
                    <span
                      v-b-tooltip.noninteractive.hover
                      class="file-name"
                      :title="file.id ? file.mime_type : file.file.type"
                    >
                      {{ file.id ? file.mime_type : file.file.type }}
                    </span>
                  </b-th>
                  <b-th>
                    <span
                      v-b-tooltip.noninteractive.hover
                      class="file-name"
                      :title="getCategoryName(file.attachment_category_id)"
                    >
                      {{ getCategoryName(file.attachment_category_id) }}
                    </span>
                  </b-th>
                  <b-th>
                    <div class="d-flex align-items-center">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="attachments[index].action"
                        class="d-inline-block m-0"
                        name="checkbox-1"
                        value="send"
                        unchecked-value="store"
                      />
                      <strong>{{ attachments[index].action }}</strong>
                    </div>
                  </b-th>
                  <b-th>
                    <div
                      class="d-flex justify-content-end"
                      style="gap: 8px"
                    >
                      <div class="upload-file__files-list-item-del">
                        <feather-icon
                          icon="LTrashIcon"
                          size="28"
                          style="padding: 5px"
                          class="cursor-pointer border-dotted feather-trash-icon"
                          @click="deleteAttachment(file.id, index)"
                        />
                      </div>
                      <div class="d-flex">
                        <span>
                          <span>
                            <feather-icon
                              v-if="file.id"
                              icon="LDownloadIcon"
                              size="28"
                              style="padding: 5px"
                              class="cursor-pointer border-dotted feather-download-icon"
                              @click="uploadAttachment(file, index)"
                            />
                          </span>
                        </span>
                        <span>
                          <a @click="showAttachment(file)">
                            <feather-icon
                              icon="LEyeIcon"
                              size="28"
                              style="padding: 5px"
                              class="cursor-pointer border-dotted feather-eye-icon"
                            />
                          </a>
                        </span>
                      </div>
                    </div>
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>

      </div>
      <drag-zone-file-upload
        ref="refAttachmentFileUpload"
        v-model="chosenFiles"
        class="mb-1"
        :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
        @input="showModalToChoseCategory"
      />
      <!-- ===================================== -->
    </div>
    <b-modal
      id="attachment-create-modal"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Upload"
      ok-variant="success"
      :ok-disabled="isOkUploadBtnActive"
      :cancel-disabled="isCancelUploadBtnActive"
      cancel-variant="outline-primary"
      @hidden="clearData"
      @ok="uploadFile"
    >
      <b-overlay :show="isUploading">
        <div class="l-modal__container">
          <b-row>
            <b-col cols="12">
              <h3 class="l-modal__title">
                {{ $t('What category is this file?') }}
              </h3>
            </b-col>
            <b-col>
              <l-select
                v-model="sendData.attachment_category_id"
                name="attachment_category_id"
                :field="{
                  options: { transition: '' },
                  store: 'attachmentCategoryListContact',
                }"
              />
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BThead,
  BTr,
  BTh,
  BModal,
  BOverlay,
  BSpinner,
  BTableSimple,
  BTbody,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import axios from 'axios'
import DragZoneFileUpload from '@/views/main/orders/components/use-as-global/DragZoneFileUpload.vue'
import config from '../../../config'

export default {
  name: 'UploadAttachment',
  components: {
    DragZoneFileUpload,
    BRow,
    BCol,
    BThead,
    BTr,
    BTh,
    BModal,
    BOverlay,
    BTbody,
    BSpinner,
    BTableSimple,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {},
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      isUploading: false,
      isLoading: false,
      chosenFiles: null,
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      sendData: {
        attachment_category_id: null,
        model_type: 1,
        file: null,
      },
    }
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray.join(',')
    },
    attachments() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm.attachments
    },
    customerID() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm.customer_id
    },
    attachmentCategoryListAssets() {
      return this.$store.state.listModule.attachmentCategoryListAssets ?? []
    },
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
  },
  methods: {
    customerInformationAttachments() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm.attachments ?? []
    },
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    showAttachment(file) {
      if (file.id) {
        window.open(file.url, '_blank')
      } else {
        window.open(
          (window.URL || window.webkitURL).createObjectURL(file.file),
          '_blank',
        )
      }
    },
    fileInputChanged(e) {
      this.chosenFiles = e.target.files
      this.showModalToChoseCategory()
    },
    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refLFileUploadInput.value = null
    },
    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
        model_id: this.customerID,
        model_type: 0,
        action: 'store',
      }
    },
    getCategoryName(id) {
      return this.attachmentCategoryListAssets.find(item => +item.id === +id)?.name ?? 'no category'
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.hideModalToChoseCategory()
        this.isUploading = false
      }
      return isErrorAvailable
    },
    uploadFile() {
      const file = this.chosenFiles[0]
      if (this.checkTheFileType(file.type)) {
        return
      }
      if (file.size > 4 * 1024 * 1024) {
        // 4MB limit
        this.isUploading = false
        this.hideModalToChoseCategory()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      this.isUploading = true
      this.attachments.push(this.mapDataObject())
      this.isUploading = false
    },
    deleteAttachment(id, index) {
      if (id) {
        this.isLoading = true
        this.$store
          .dispatch(`${this.MODULE_NAME_ATTACHMENT}/del`, { id })
          .catch(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Deleting This file', {
                  module: this.MODULE_NAME_ATTACHMENT,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
          //  Do Something
            this.getAttachments()
          })
      } else {
        this.customerInformationAttachments().splice(index, 1)
      }
    },
    uploadAttachment(file) {
      if (file) {
        this.show = true
        const fileUrl = file.url
        axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob',
        }).then(res => {
          const fileBlob = window.URL.createObjectURL(new Blob([res.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileBlob
          const fileName = fileUrl.split('/').pop()
          fileLink.setAttribute('download', fileName)
          fileLink.setAttribute('id', 'test-download')
          // fileLink.setAttribute('target', '_blank')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: i18n.t('Error Uploading This file', {
                module: this.MODULE_NAME,
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.show = false
        })
      }
    },
    getAttachments() {
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getActiveAttachment`, { id: this.customerID }).finally(() => {
        this.isLoading = false
      })
    },
  },
  setup() {
    const { uploadAttachmentTableCol, MODULE_NAME, MODULE_NAME_ATTACHMENT } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_ATTACHMENT,
      uploadAttachmentTableCol,
    }
  },
}
</script>
<style lang="scss" scoped>
.is-editable-file-upload {
  opacity: 0.5;
  pointer-events: none;
}

.upload-attachment-table {
  margin-bottom: 0;

  th {
    padding: 5px !important;
    font-weight: normal;
  }

  & .file-name,
  & .file-data {
    width: 100% !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;
  }

  &--disabled{
    pointer-events: none;
    opacity: 0.5;
  }

}
</style>
