<template>
  <div class="l-file-upload">
    <div
      class="l-file-upload__dragzone"
      @dragover.prevent
      @click="clickFileInput"
      @drop.prevent="dropped"
    >
      <p class="l-file-upload__dragzone-text">
        Drop Files Here to Upload
      </p>

      <input
        v-show="false"
        ref="refLFileUploadInput"
        :accept="formattedAcceptProps"
        type="file"
        @input="fileInputChanged"
      >
    </div>

  </div>
</template>
<script>
export default {
  name: 'DragZoneFileUpload',
  props: {
    value: {},
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray
    },
  },
  methods: {
    dropped(e) {
      this.$emit('input', this.onlyAcceptableFiles(e.dataTransfer.files))
    },
    onlyAcceptableFiles(filesArray) {
      // todo add check for it
      return filesArray
    },
    fileInputChanged(e) {
      this.$emit('input', e.target.files)
    },
    clearInputValue() {
      this.$refs.refLFileUploadInput.value = null
    },
    clickFileInput() {
      this.$refs.refLFileUploadInput.click()
    },
  },
}
</script>
<style lang="scss">
.l-file-upload {

  &__dragzone {
    height: 116px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #DDE3E7;
    border-radius: 5px;

    cursor: pointer;
  }

  &__dragzone-text {
    margin: auto;
  }
}
</style>
