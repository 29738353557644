import {
  CHECKBOX_LEF,
  FLAT_PICKER,
  L_NUMERIC_INPUT,
  L_TIME_PICKER_INPUT,
  SELECT_CHILD_INPUT,
  SELECT_INPUT,
  TEXT_INPUT,
  TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'
import { formatNumberToCurrencyView } from '@core/utils/utils'

export default function config() {
  // Constants
  const MODULE_NAME = 'rental-sales'
  const MODULE_NAME_OTHER_CHARGES = 'other-charges'
  const MODULE_NAME_ATTACHMENT = 'attachment'
  const MODULE_NAME_CLONE = 'cloneDats'
  const MODULE_NAME_ORDERS = 'orders'
  const MODULE_NAME_CATALOG = 'catalog'

  // Order Information
  const ORDER_INFORMATION_FORM_TITLE = 'Order Information'
  const OTHER_CHARGES_FORM_TITLE = 'Other Charges'
  const UPDATE_PAGE_TITLE = 'Quote '
  const orderInformationFields = {
    // is_rental: {
    //   type: CHECKBOX_LEF,
    //   label: 'Rental',
    // },
    // is_sale: {
    //   type: CHECKBOX_LEF,
    //   label: 'Sale',
    // },
    account: {},
    po_number: {
      type: TEXT_INPUT,
      label: 'Purchase Order Number/Reference',
      placeholder: 'Purchase Order Number/Reference',
      rules: '',
    },
    receive_method: {
      type: SELECT_INPUT,
      label: '',
      store: 'receiveMethodsList',
      rules: 'required',
      options: {
        label: 'name',
      },
    },
    // pick_up_by_data: {
    //   type: FLAT_PICKER,
    //   label: '',
    //   rules: 'required',
    //   hasOperationHours: true,
    //   options: {
    //     config: {
    //       'is-calendar-icon': true,
    //       allowInput: true,
    //     },
    //   },
    // },
    // pick_up_by_time: {
    //   type: L_TIME_PICKER_INPUT,
    //   label: '',
    //   rules: 'required',
    // },
    // pick_up_by_customer: {
    //   type: SELECT_INPUT,
    //   label: '',
    //   store: '',
    //   rules: 'required',
    //   options: {
    //     label: 'label',
    //   },
    // },

    // return_data: {
    //   type: FLAT_PICKER,
    //   label: '',
    //   rules: 'required',
    //   hasOperationHours: true,
    //   options: {
    //     config: {
    //       'is-calendar-icon': true,
    //       allowInput: true,
    //     },
    //   },
    // },
    // return_time: {
    //   type: L_TIME_PICKER_INPUT,
    //   label: '',
    //   rules: 'required',
    // },
    // return_customer: {
    //   type: SELECT_INPUT,
    //   label: '',
    //   store: '',
    //   rules: 'required',
    //   options: {
    //     label: 'label',
    //   },
    // },
  }

  const checkIsAllRequiredFieldsFilled = (
    formObject,
    requiredFieldsList,
    ctx,
    actionName,
  ) => {
    const everyObjectKeyWords = Object.keys(formObject)
    const isExistedNeededFields = []

    for (let i = 0; i < requiredFieldsList.length; i += 1) {
      for (let j = 0; j < everyObjectKeyWords.length; j += 1) {
        if (requiredFieldsList[i] === everyObjectKeyWords[j]) {
          isExistedNeededFields.push(true)
        }
      }
    }

    const checkIfExistsAllNeededFields = isExistedNeededFields.every(item => item)
      && isExistedNeededFields.length === requiredFieldsList.length

    ctx.$store.commit(
      `${MODULE_NAME}/${actionName}`,
      checkIfExistsAllNeededFields,
    )
  }
  // const orderInformationRequiredFields = ['is_rental', 'is_sale']
  const autoSuggestTableColumnsAccount = [
    // Set as comment, needs to render icon
    // {
    //   key: 'icon',
    //   label: '',
    //   getValue: (value, data) => `${data.default_contact.cell_no}`,
    // },
    // {
    //   key: 'first_name',
    //   label: '',
    //   getValue: (value, data) => `${data.default_contact.first_name} ${data.default_contact.last_name}`,
    // },
    {
      key: 'cust_name_dba',
      label: '',
    },
  ]

  // Customer Information
  const CUSTOMER_INFORMATION_FORM_TITLE = 'Customer Information'

  const customerInformationFields = {
    billing_contact_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Billing Contact',
      store: 'billingContact',
      rules: 'required',
      options: {
        label: 'fullName',
      },
      firstSelect: true,
    },
    // payment_term_id: {
    //   type: SELECT_INPUT,
    //   label: 'Payment Terms',
    //   store: 'paymentTermList',
    //   rules: 'required',
    //   options: {
    //     label: 'label',
    //   },
    // },
    billing_phone: {
      type: TEXT_INPUT,
      label: 'Phone',
      placeholder: ' ',
    },
    billing_email: {
      type: TEXT_INPUT,
      label: 'Email',
      placeholder: ' ',
    },
    billing_address: {
      type: TEXTAREA_INPUT,
      label: 'Address',
      placeholder: ' ',
    },
    price_tier: {
      type: TEXT_INPUT,
      label: 'Price Tier',
      placeholder: ' ',
    },
    customer_notes: {
      type: TEXTAREA_INPUT,
      label: 'Customer Notes',
      placeholder: ' ',
    },
    // payer_email: {
    //   type: TEXT_INPUT,
    //   label: 'Payer Email',
    // },
    // customer_discount: {
    //   type: L_NUMERIC_INPUT,
    //   label: 'Customer Discount',
    //   unitName: 'name_length',
    //   factorPhy: 'factor_length',
    //   rules: 'required|numeric|max_value:100',
    //   isFlexible: true,
    // },
    order_rules_required_security_deposit: {
      type: CHECKBOX_LEF,
      label: 'Require security deposit',
    },
    return_contact_is_different: {
      type: CHECKBOX_LEF,
      label: 'Return contact is different',
    },
    dispatch_contact_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Dispatch Contact',
      store: 'shippingContact',
      rules: 'required',
      options: {
        label: 'fullName',
      },
    },
    order_placed_by_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Order Placed By',
      store: 'orderDispatchList',
      rules: 'required',
      options: {
        label: 'full_name',
      },
    },
    dispatch_phone: {
      type: TEXT_INPUT,
      label: 'Dispatch Phone',
      placeholder: ' ',
    },
    dispatch_email: {
      type: TEXT_INPUT,
      label: 'Dispatch Email',
      placeholder: ' ',
    },
  }

  //  Insurance Information
  const INSURANCE_INFORMATION_FORM_TITLE = 'Insurance Information'
  const ORDER_RULES_FORM_TITLE = 'Order Rules'

  const insuranceInformationFields = {
    policy_number: {
      type: TEXT_INPUT,
      label: 'Policy No.',
      placeholder: ' ',
      // rules: 'required',
    },
    amount_insured: {
      type: TEXT_INPUT,
      label: 'For Up To',
      placeholder: ' ',
    },
    deductible: {
      type: TEXT_INPUT,
      label: 'Deductible',
      placeholder: ' ',
    },
    coverage_start_date: {
      type: FLAT_PICKER,
      label: 'From',
      // rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    coverage_end_date: {
      type: FLAT_PICKER,
      label: 'To',
      // rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
          dateFormat: 'Y-m-d H:i:s',
        },
      },
    },
  }
  // Dispatch and Return
  const DISPATCH_FORM_TITLE = 'Dispatch'
  const RETURN_FORM_TITLE = 'Return'
  const dispatchAndReturnFields = {
    dispatch_warehouse_id: {
      type: SELECT_INPUT,
      label: 'Dispatch Warehouse',
      store: 'warehouseList',
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    dispatch_method: {
      type: SELECT_INPUT,
      label: 'Dispatch Method',
      store: 'dispatchAndReturnMethodsList',
      rules: 'required',
      options: {
        label: 'name',
      },
    },
    return_method: {
      type: SELECT_INPUT,
      label: 'Return Method',
      store: 'dispatchAndReturnMethodsList',
      // rules: 'required',
      options: {
        label: 'name',
      },
    },
    order_routing_order_returns_to_another_warehouse: {
      type: CHECKBOX_LEF,
      label: 'Order returns to another warehouse',
    },
    order_routing_allow_dispatch_of_items_from_warehouse_of_origin: {
      type: CHECKBOX_LEF,
      label: 'Allow dispatch of items from warehouse of origin',
    },
    return_warehouse_id: {
      type: SELECT_INPUT,
      label: 'Return Warehouse',
      store: 'warehouseList',
      rules: '',
      options: {
        label: 'label',
      },
    },
    internal_shipping_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal Shipping Notes (not visible to the customer)',
    },
    payer_email: {
      type: TEXT_INPUT,
      label: 'Payer Email',
    },
    // return_contact_is_different: {
    //   type: CHECKBOX_LEF,
    //   label: 'Return contact is different',
    // },
    // dispatch_contact_id: {
    //   type: SELECT_CHILD_INPUT,
    //   label: 'Dispatch Contact',
    //   store: 'shippingContact',
    //   rules: 'required',
    //   options: {
    //     label: 'fullName',
    //   },
    // },
    payment_term_id: {
      type: SELECT_INPUT,
      label: 'Payment Terms',
      store: 'paymentTermList',
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    customer_discount: {
      type: L_NUMERIC_INPUT,
      label: 'Customer Discount',
      unitName: 'name_length',
      factorPhy: 'factor_length',
      rules: 'required|numeric|max_value:100',
      isFlexible: true,
    },
    // dispatch_phone: {
    //   type: TEXT_INPUT,
    //   label: 'Dispatch Phone',
    //   placeholder: ' ',
    // },
    // dispatch_email: {
    //   type: TEXT_INPUT,
    //   label: 'Dispatch Email',
    //   placeholder: ' ',
    // },
    return_contact_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Return Contact',
      store: 'orderDispatchContactList',
      rules: 'required',
      options: {
        label: 'full_name',
      },
    },
    return_phone: {
      type: TEXT_INPUT,
      label: 'Return Phone',
      placeholder: ' ',
    },
    return_email: {
      type: TEXT_INPUT,
      label: 'Return Email',
      placeholder: ' ',
    },
    dispatch_address_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Dispatch Address',
      store: 'dispatchAndReturnAddress',
      rules: 'required',
      options: {
        label: 'fullAddress',
      },
    },
    return_address_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Return Address',
      store: 'dispatchAndReturnAddress',
      rules: 'required',
      options: {
        label: 'fullAddress',
      },
    },
  }

  const dispatchAndReturnRequiredFields = [
    'return_address_id',
    'dispatch_warehouse_id',
    'dispatch_method',
    'dispatch_contact_id',
    'dispatch_address_id',
  ]
  // Event Information
  const EVENT_INFORMATION_FORM_TITLE = 'Event Information'
  const eventInformationFields = {
    event_information_rental_start_dispatch: {
      type: FLAT_PICKER,
      label: 'Rental Start (dispatch)',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          dateFormat: 'Y-m-d',
          allowInput: true,
        },
      },
    },
    event_information_rental_end: {
      type: FLAT_PICKER,
      label: 'Rental End (receive)',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          dateFormat: 'Y-m-d',
          allowInput: true,
        },
      },
    },
    event_reference: {
      type: TEXT_INPUT,
      label: 'Event Reference',
    },
    event_information_dispatch_date: {
      type: FLAT_PICKER,
      label: 'Dispatch Date',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          allowInput: true,
        },
      },
    },
    event_information_rental_start_dispatch_time: {
      type: L_TIME_PICKER_INPUT,
      label: 'Dispatch Time',
      rules: 'required',
    },
    event_information_rental_end_time: {
      type: L_TIME_PICKER_INPUT,
      label: 'Receive Time',
      rules: 'required',
    },
  }

  const eventInformationRequiredFields = [
    'event_information_rental_start_dispatch',
    'event_information_rental_end',
    'event_information_rental_start_dispatch_time',
    'event_information_rental_end_time',
  ]
  // Order Items
  const ORDER_ITEMS_FORM_TITLE = 'Order Items'
  // Totals
  const TOTALS_FORM_TITLE = 'Totals'
  const TOTALS_FORM_ORDER_DETAILS_TITLE = 'Order Details'
  const TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE = 'Totals'
  const TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL = 'TOTAL'

  const totalsFields = {
    total_order_notes: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes (visible to the customer)',
      placeholder: 'Write notes here...',
      options: {
        rows: 12,
      },
    },
  }

  const orderDetails = {
    rental_duration: {
      title: 'Rental Duration',
      extraTitle: 'calendar days',
    },
    days_charged: {
      title: 'Days Charged',
      extraTitle: 'days',
    },
  }

  const totalsComputedTable = [
    {
      title: 'Rentals',
      key: 'rent',
    },
    {
      title: 'Sales and Other Charges',
      key: 'sale_other_charges',
    },
    {
      title: 'Discounts',
      key: 'discount',
    },
    {
      title: 'Taxes',
      key: 'taxes',
    },
  ]
  const mapDate = date => {
    const getDate = new Date(date)

    return [getDate.getFullYear(), getDate.getMonth() + 1, getDate.getDate()]
      .map(x => x.toString().padStart(2, '0'))
      .join('-')
  }

  const mapTime = time => {
    if (!time) return ''

    return time.split(':').slice(0, 2).join(':')
  }

  const PAYER_ACCOUNT_RENTER = 'renter'
  const PAYER_ACCOUNT_THIRD_PARTY = 'third_party'

  const PAYER_ACCOUNT_THIRD_PART_CONFIRMATION_TEXT = 'If the account isn’t in the system, please enter the email address where the payment request should go to'

  const payerAccount = [
    {
      value: PAYER_ACCOUNT_RENTER,
      title: 'Paid by renter',
    },
    {
      value: PAYER_ACCOUNT_THIRD_PARTY,
      title: 'Paid by 3rd party',
    },
  ]

  const tableColumnsHybrid = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '10%' },
      tBodyThStyle: { width: '10%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%' },
      tBodyThStyle: { width: '10%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '30%' },
      tBodyThStyle: { width: '50%', textAlign: 'right' },
    },
    {
      key: 'type',
      label: 'Type',
      tBodyThStyle: { width: '50%', textAlign: 'right' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '14%', textAlign: 'center' },
    },
    {
      key: 'days',
      label: 'Days',
      thStyle: { textAlign: 'right' },
    },
    {
      key: 'rental_price',
      label: 'Rate',
      thStyle: { textAlign: 'right', width: '8%' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { textAlign: 'right', width: '8%' },
      tBodyThStyle: { width: '25%' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { textAlign: 'right', width: '8%' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { textAlign: 'right', width: '6%' },
    },
    {
      key: 'total',
      label: 'TOTAL',
      thStyle: { textAlign: 'right', width: '8%' },
    },
  ]

  // const tableColumnsRental = [
  //   {
  //     key: 'actions',
  //     label: '',
  //     thStyle: { width: '10%' },
  //     tBodyThStyle: { width: '10%' },
  //   },
  //   {
  //     key: 'sku',
  //     label: 'SKU',
  //     thStyle: { width: '10%' },
  //     tBodyThStyle: { width: '10%' },
  //   },
  //   {
  //     key: 'name',
  //     label: 'Name',
  //     thStyle: { width: '30%' },
  //     tBodyThStyle: { width: '50%', textAlign: 'right' },
  //   },
  //   {
  //     key: 'units',
  //     label: 'Units',
  //     thStyle: { width: '14%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'days',
  //     label: 'Days',
  //     thStyle: { textAlign: 'right' },
  //   },
  //   {
  //     key: 'rental_price',
  //     label: 'Rate',
  //     thStyle: { textAlign: 'right', width: '8%' },
  //   },
  //   {
  //     key: 'discount',
  //     label: 'Discount',
  //     thStyle: { textAlign: 'right', width: '8%' },
  //     tBodyThStyle: { width: '25%' },
  //   },
  //   {
  //     key: 'subtotal',
  //     label: 'Subtotal',
  //     thStyle: { textAlign: 'right', width: '8%' },
  //   },
  //   {
  //     key: 'taxes',
  //     label: 'Taxes',
  //     thStyle: { textAlign: 'right', width: '6%' },
  //   },
  //   {
  //     key: 'total',
  //     label: 'TOTAL',
  //     thStyle: { textAlign: 'right', width: '8%' },
  //   },
  // ]

  // const tableColumnsSales = [
  //   {
  //     key: 'actions',
  //     label: '',
  //     thStyle: { width: '10%' },
  //     tBodyThStyle: { width: '10%' },
  //   },
  //   {
  //     key: 'sku',
  //     label: 'SKU',
  //     thStyle: { width: '10%' },
  //     tBodyThStyle: { width: '10%' },
  //   },
  //   {
  //     key: 'name',
  //     label: 'Name',
  //     thStyle: { width: '34%' },
  //     tBodyThStyle: { width: '38%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'units',
  //     label: 'Units',
  //     thStyle: { width: '14%', textAlign: 'center' },
  //     tBodyThStyle: { width: '14%' },
  //   },
  //   {
  //     key: 'price',
  //     label: 'Price',
  //     thStyle: { width: '10%', textAlign: 'center' },
  //     tBodyThStyle: { width: '10%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'discount',
  //     label: 'Discount',
  //     thStyle: { width: '10%', textAlign: 'center' },
  //     tBodyThStyle: { width: '10%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'subtotal',
  //     label: 'Subtotal',
  //     thStyle: { width: '10%', textAlign: 'center' },
  //     tBodyThStyle: { width: '10%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'taxes',
  //     label: 'Taxes',
  //     thStyle: { width: '10%', textAlign: 'center' },
  //     tBodyThStyle: { width: '10%', textAlign: 'center' },
  //   },
  //   {
  //     key: 'total',
  //     label: 'TOTAL',
  //     thStyle: { width: '10%', textAlign: 'center' },
  //     tBodyThStyle: { width: '10%', textAlign: 'center' },
  //   },
  // ]

  const rentalTableAutoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'available',
      label: 'Available',
    },
    {
      key: 'other_locations',
      label: 'Other Locations',
    },
    {
      key: 'rental_price',
      label: 'Rate',
    },
  ]
  const saleTableAutoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'available',
      label: 'Available',
    },
    {
      key: 'other_locations',
      label: 'Other Locations',
    },
    {
      key: 'sale_price',
      label: 'Rate',
    },
  ]
  const ORDER_ITEMS_RENTALS_TABLE_TITLE = 'Rental'
  const ORDER_ITEMS_SALES_TABLE_TITLE = 'Sales'

  const tableColumnsOtherChanes = [
    {
      key: 'show_details',
      label: '',
      thStyle: { width: '9%' },
      tBodyThStyle: { width: '10%' },
    },

    {
      key: 'sku',
      label: 'Sku',
      thStyle: { width: '10%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '34%' },
      tBodyThStyle: { width: '46%', textAlign: 'right' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '14%', textAlign: 'center' },
      tBodyThStyle: { width: '7%' },
    },

    {
      key: 'rates',
      label: 'Price',
      thStyle: { width: '15%', textAlign: 'center' },
      tBodyThStyle: { width: '15%', textAlign: 'center' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { width: '15%', textAlign: 'center' },
      tBodyThStyle: { width: '15%', textAlign: 'center' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { width: '15%', textAlign: 'center' },
      tBodyThStyle: { width: '15%', textAlign: 'center' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { width: '15%', textAlign: 'center' },
      tBodyThStyle: { width: '15%', textAlign: 'center' },
    },
    {
      key: 'total',
      label: 'TOTAL',
      thStyle: { width: '15%', textAlign: 'center' },
      tBodyThStyle: { width: '15%', textAlign: 'center' },
    },
  ]

  const otherChanesTableAutoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'available',
      label: 'Available',
    },
    {
      key: 'other_locations',
      label: 'Other Locations',
    },
    {
      key: 'rate',
      label: 'Rate',
      getValue: (value, data) => formatNumberToCurrencyView(data.rates[0]?.rate) ?? '',
    },
  ]
  const getProps = (fieldName, fields) => ({
    is: fields[fieldName].type,
    field: fields[fieldName],
    name: fieldName,
  })
  // const orderItemsSalesFields = {
  //   taxes: {
  //     type: L_CURRENCY_MASK_INPUT,
  //     label: '',
  //     placeholder: '$0',
  //   },
  // }
  return {
    mapTime,
    mapDate,
    getProps,
    MODULE_NAME,
    MODULE_NAME_CATALOG,
    MODULE_NAME_OTHER_CHARGES,
    totalsFields,
    payerAccount,
    orderDetails,
    tableColumnsHybrid,
    // orderItemsSalesFields,
    tableColumnsOtherChanes,
    otherChanesTableAutoSuggestTableColumns,
    rentalTableAutoSuggestTableColumns,
    ORDER_ITEMS_RENTALS_TABLE_TITLE,
    MODULE_NAME_ORDERS,
    totalsComputedTable,
    checkIsAllRequiredFieldsFilled,
    autoSuggestTableColumnsAccount,
    ORDER_INFORMATION_FORM_TITLE,
    orderInformationFields,
    dispatchAndReturnFields,
    // orderInformationRequiredFields,
    MODULE_NAME_CLONE,
    CUSTOMER_INFORMATION_FORM_TITLE,
    INSURANCE_INFORMATION_FORM_TITLE,
    MODULE_NAME_ATTACHMENT,
    DISPATCH_FORM_TITLE,
    RETURN_FORM_TITLE,
    OTHER_CHARGES_FORM_TITLE,
    ORDER_RULES_FORM_TITLE,
    customerInformationFields,
    insuranceInformationFields,
    dispatchAndReturnRequiredFields,
    EVENT_INFORMATION_FORM_TITLE,
    eventInformationFields,
    eventInformationRequiredFields,
    ORDER_ITEMS_FORM_TITLE,
    TOTALS_FORM_TITLE,
    TOTALS_FORM_ORDER_DETAILS_TITLE,
    PAYER_ACCOUNT_RENTER,
    PAYER_ACCOUNT_THIRD_PARTY,
    TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
    TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
    PAYER_ACCOUNT_THIRD_PART_CONFIRMATION_TEXT,
    ORDER_ITEMS_SALES_TABLE_TITLE,
    saleTableAutoSuggestTableColumns,
    formatNumberToCurrencyView,
  }
}
