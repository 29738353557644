<template>
  <div class="form-wrapper">
    <div>
      <!--      <h3 class="font-weight-bolder mb-1">-->
      <!--        {{ $t(EVENT_INFORMATION_FORM_TITLE) }}-->
      <!--      </h3>-->
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="eventInformationFields['event_reference'].type"
          ref="event_reference_ref"
          v-model="eventInformation['event_reference']"
          v-bind="getProps('event_reference', eventInformationFields, eventInformation['event_reference'])"
        />
        <component
          :is="eventInformationFields['event_information_rental_start_dispatch'].type"
          v-model="eventInformation['event_information_rental_start_dispatch']"
          v-b-tooltip.noninteractive.hover
          :title="$t('Date selection is available only if insurance coverage dates are within valid range')"
          v-bind="getProps('event_information_rental_start_dispatch', eventInformationFields, eventInformation['event_information_dispatch_date'],endDateOfInsurance)"
        />
        <component
          :is="eventInformationFields['event_information_rental_end'].type"
          ref="event_information_rental_end_ref"
          v-model="eventInformation['event_information_rental_end']"
          v-b-tooltip.noninteractive.hover
          :title="$t('Date selection is available only if insurance coverage dates are within valid range')"
          v-bind="getProps('event_information_rental_end', eventInformationFields, this.eventInformation.event_information_rental_start_dispatch,endDateOfInsurance)"
        />
      </b-col>
      <b-col md="6">
        <component
          :is="eventInformationFields['event_information_dispatch_date'].type"
          v-model="eventInformation['event_information_dispatch_date']"
          v-b-tooltip.noninteractive.hover
          :title="$t('Date selection is available only if insurance coverage dates are within valid range')"
          v-bind="getProps('event_information_dispatch_date', eventInformationFields, new Date(),endDateOfInsurance)"
        />
        <component
          :is="eventInformationFields['event_information_rental_start_dispatch_time'].type"
          id="event_information_rental_start_dispatch_time"
          ref="event_information_rental_start_dispatch_time_ref"
          v-model="eventInformation['event_information_rental_start_dispatch_time']"
          v-bind="getProps('event_information_rental_start_dispatch_time', eventInformationFields)"
          the-size="sm"
          height="39px"
          :is-only-button="true"
        >
          <template #input-extra-el>
            <b-form-input
              id="event_information_rental_start_dispatch_time"
              v-model="eventInformation['event_information_rental_start_dispatch_time']"
              type="time"
            />
          </template>
        </component>
        <component
          :is="eventInformationFields['event_information_rental_end_time'].type"
          id="event_information_rental_end_time"
          ref="event_information_rental_end_time_ref"
          v-model="eventInformation['event_information_rental_end_time']"
          v-bind="getProps('event_information_rental_end_time', eventInformationFields)"
          :is-only-button="true"
          the-size="sm"
          height="39px"
        >
          <template #input-extra-el>
            <b-form-input
              id="event_information_rental_end_time"
              v-model="eventInformation['event_information_rental_end_time']"
              type="time"
            />
          </template>
        </component>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import { format, getTime } from 'date-fns'
import config from '../../create/config'

export default {
  name: 'EventInformation',
  components: {
    BRow,
    BCol,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    warehouseDate() {
      return this.$store.state.horizontalMenu.dateTimeOfWarehouse
    },
    endDateOfInsurance() {
      return this.$store.state[this.MODULE_NAME].insuranceInformationForm.coverage_end_date
    },
  },
  watch: {
    eventInformation(obj) {
      this.checkIsAllRequiredFieldsFilled(obj, this.eventInformationRequiredFields, this, 'SET_IS_EVENT_INFORMATION_REQUIRED_FIELDS_FILLED')
    },
    warehouseDate(date) {
      this.setDefaultData(date)
    },
  },
  methods: {
    getProps(fieldName, fields, date = null, maxDate) {
      return {
        is: fields[fieldName].type,
        field: {
          ...fields[fieldName],
          options: { config: { ...fields[fieldName]?.options?.config, minDate: date, maxDate } },
        },
        name: fieldName,
      }
    },
    setDefaultData(date) {
      this.$store.commit(`${this.MODULE_NAME}/SET_EVENT_INFORMATION_FORM`, {
        ...this.eventInformation,
        event_information_rental_start_dispatch: getTime(date),
        event_information_rental_start_dispatch_time: format(date, 'HH:mm'),
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      eventInformationFields,
      EVENT_INFORMATION_FORM_TITLE,
      checkIsAllRequiredFieldsFilled,
      eventInformationRequiredFields,
    } = config()

    return {
      MODULE_NAME,
      eventInformationFields,
      EVENT_INFORMATION_FORM_TITLE,
      checkIsAllRequiredFieldsFilled,
      eventInformationRequiredFields,
    }
  },
}
</script>
<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
