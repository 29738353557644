<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(TOTALS_FORM_TITLE) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="totalsFields['total_order_notes'].type"
          ref="total_order_notes_ref"
          v-model="totalsForm['total_order_notes']"
          class="mb-0"
          v-bind="getProps('total_order_notes', totalsFields)"
        />
      </b-col>
      <b-col md="6">
        <!--    Order Details    -->
        <div class="mb-2">
          <h4 class="font-weight-bolder mb-1">
            {{ $t(TOTALS_FORM_ORDER_DETAILS_TITLE) }}
          </h4>
          <div class="pl-1">
            <div
              v-for="field in ['rental_duration', 'days_charged']"
              :key="field"
            >
              <div
                class="d-flex"
                style="gap: 12px"
              >
                <b
                  class="d-inline-block"
                  style="min-width: 150px"
                >
                  {{ '' }}
                </b>
                {{ '' }}
                <span>{{ '' }}</span>
              </div>
            </div>
          </div>
        </div>

        <!--    Totals Computed table   -->
        <h4 class="font-weight-bolder mb-1">
          {{ $t(TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE) }}
        </h4>
        <b-list-group>
          <b-list-group-item
            v-for="({ title,key}, index) in totalsComputedTable"
            :key="index"
            :class="`totals-form-list-group-item ${(index + 1) % 2 === 0 ? 'totals-form-list-group-item-light': ''}`"
          >
            <div class="d-flex justify-content-between">
              <span>
                {{ $t(title) }}
              </span>
              <span>
                ${{ '' }}
              </span>
            </div>
          </b-list-group-item>
          <b-list-group-item class="totals-form-list-group-item">
            <div class="d-flex justify-content-between">
              <b>
                {{ $t(TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL) }}
              </b>
              <b>
                ${{ '' }}
              </b>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'

import config from '../create/config'

export default {
  name: 'Totals',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    totalsForm() {
      return this.$store.state[this.MODULE_NAME].totalsForm
    },
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },

    orderItemsOtherChargesArr() {
      const otherProducts = this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable.otherCharges?.map(i => ({
        subTotal: (i.price - (i.price * i.discount) / 100) * i.amount,
      }))
      return this.sumTotalAmountOfGivenArrayByProperty(
        otherProducts,
        'subTotal',
      )
    },
    orderItemsRentalArr() {
      const products = this.$store.state[this.MODULE_NAME].orderItemsRentalTable.products?.filter(i => i.type === 'rent')
      return this.sumTotalAmountOfGivenArrayByProperty(
        products,
        'itemTotal',
      )
    },
    orderItemsSalesArr() {
      const sales = this.$store.state[this.MODULE_NAME].orderItemsSalesTable.products?.filter(i => i.type === 'sale')?.map(sale => ({
        subTotal: (sale.sale_price - (sale.sale_price * sale.discount) / 100) * sale.amount,
      }))
      return this.sumTotalAmountOfGivenArrayByProperty(
        sales,
        'subTotal',
      )
    },

    totals() {
      return this.$store.state[this.MODULE_NAME].totalsForm
    },
  },
  methods: {

    // allProductsDiscount() {
    //   const sales = this.$store.state[this.MODULE_NAME].orderItemsSalesTable.products?.filter(i => i.type === 'sale')?.reduce((acc, item) => acc + ((item.sale_price * item.discount) / 100), 0) || 0
    //   const rent = this.$store.state[this.MODULE_NAME].orderItemsRentalTable.products?.filter(i => i.type === 'rent')?.reduce((acc, item) => acc + Number(item.discountPrice), 0) || 0
    //   const otherProducts = this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable.otherCharges?.reduce((acc, item) => acc + ((item.price * item.discount) / 100), 0) || 0
    //   return sales + rent + otherProducts
    // },
    //
    // calculateTotalProductPrice({ key }) {
    //   const productsPrice = {
    //     rent: this.orderItemsRentalArr,
    //     sale_other_charges: this.orderItemsSalesArr + this.orderItemsOtherChargesArr,
    //     discount: this.allProductsDiscount(),
    //     taxes: 0,
    //   }
    //
    //   return productsPrice[key] ?? 0
    // },

    // totalPrice() {
    //   const total = [this.calculateTotalProductPrice({ key: 'rent' }), this.calculateTotalProductPrice({ key: 'sale_other_charges' }), this.calculateTotalProductPrice({ key: 'taxes' })]
    //   return total.reduce((a, b) => a + b, 0).toFixed(2)
    // },

    // countDays(StartDate, EndDate) {
    //   if (!StartDate || !EndDate) {
    //     return 0
    //   }
    //   const startDay = new Date(StartDate)
    //   const endDay = new Date(EndDate)
    //   const oneDay = 1000 * 60 * 60 * 24
    //
    //   // A day in UTC always lasts 24 hours
    //   const start = Date.UTC(endDay.getFullYear(), endDay.getMonth(), endDay.getDate())
    //   const end = Date.UTC(startDay.getFullYear(), startDay.getMonth(), startDay.getDate())
    //   if (!startDay && !endDay) {
    //     return 0
    //   }
    //   return (start - end) / oneDay
    // },
  },
  setup() {
    const {
      getProps,
      MODULE_NAME,
      totalsFields,
      orderDetails,
      TOTALS_FORM_TITLE,
      totalsComputedTable,
      TOTALS_FORM_ORDER_DETAILS_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      orderDetails,
      totalsFields,
      TOTALS_FORM_TITLE,
      totalsComputedTable,
      TOTALS_FORM_ORDER_DETAILS_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
      sumTotalAmountOfGivenArrayByProperty,
    }
  },
}
</script>
<style lang="scss">
.totals-form-list-group-item {
  padding: 5px 1.25rem !important;

  &:hover {
    background-color: transparent !important;
  }

  &-light {
    background-color: #FBFBFB !important;

    &:hover {
      background-color: #FBFBFB !important;
    }
  }

}
</style>
