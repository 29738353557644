<template>
  <div class="form-wrapper">
    <l-draggable-table-list-collector
      ref="lTableRef"
      :has-footer="true"
      :module-name="MODULE_NAME"
      :fetched-data="fetchedRentals()"
      :table-columns="tableColumnsOtherChanes"
      :additional-object-for-selected-item="{ note: '',
                                              isNoteOpen: false,
                                              rental_start: eventInformationRentalStart,
                                              rental_end: eventInformationRentalEnd,
                                              inheritFromOrder: true,
                                              event_information_rental_start_dispatch_time: eventInformationRentalStartDispatchTime,
                                              event_information_rental_start_end_time: eventInformationRentalEndTime,
      }"
      :table-suggestion-configs="otherChanesTableAutoSuggestTableColumns"
      :has-below-actions="true"
      class="rental-sales-table"
      :table-config-options="{
        endpoint: `${MODULE_NAME_CATALOG}/nonStocksActiveList`
      }"
      @getCollectedList="getCollectedList"
      @rowHoveredHandler="rowHoveredHandler"
      @rowUnHoveredHandler="rowUnHoveredHandler"
    >
      <template #cell(show_details)="{data}">
        <div
          v-if="data.id"
          class="d-flex p-0"
        >
          <feather-icon
            v-b-tooltip.noninteractive.click.bottom
            icon="LTrashIcon"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Remove')"
            size="30"
            style="padding: 4px"
            @click="remove({id: data.id})"
          />
          <feather-icon
            v-b-tooltip.noninteractive.click.bottom
            icon="LEyeIcon"
            size="30"
            style="padding: 4px"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Watch')"
            @click="navigateToProduct(data, $event)"
          />

        </div>
      </template>
      <!--      <template #cell(show_row_details)="data">-->
      <!--        {{ data }}-->
      <!--      </template>-->
      <template #cell(sku)="{data}">
        <div class="text-center">
          {{ data.sku ? data.sku : '-' }}
        </div>
      </template>
      <template #cell(name)="{data}">
        <div
          v-b-tooltip.noninteractive.hover
          class="cell-row-name"
          :title="data.name"
        >
          {{ data.name }}
        </div>
      </template>
      <template #cell(units)="{ data }">
        <increment-decrement-input
          v-if="data.id"
          ref="incrementDecrementInputItemsRental"
          class="d-flex justify-content-center"
          :item="data"
          style="height: 25px; width: 60px; margin: auto"
          item-key-name-for-amount="amount"
          :on-index-comparison="data.id === getTableRowIndex"
          :is-disabled-minus="data.amount === 1"
          @onIncrementDecrementAction="onIncrementDecrementAction(data.id)"
        >
          <template #switch-off-action>
            <div
              class="switch-off-action"
            >
              {{ data.amount ? data.amount : 1 }}
            </div>
          </template>
        </increment-decrement-input>
        <div
          v-else
          style="margin-left: 47%"
        >
          -
        </div>
      </template>
      <template
        #cell(rates)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView(data.price) }}
        </span>
      </template>
      <template
        #cell(discount)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView((data.price * data.discount) / 100) }}
        </span>
      </template>
      <template
        #cell(subtotal)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView((data.price - (data.price * data.discount) / 100) * data.amount) }}        </span>
      </template>
      <template
        #cell(taxes)="{data}"
      >
        <span class="d-flex justify-content-center">
          {{ formatNumberToCurrencyView(data.tax) }}
        </span>
      </template>
      <template
        #cell(total)="{data}"
      >
        <span class="d-flex justify-content-center">{{
          formatNumberToCurrencyView(((data.price - (data.price * data.discount) / 100) * data.amount) - (data.tax === undefined ? 0 : data.tax))
        }}</span>
      </template>
      <!--  Footer Cells    -->
      <template
        #foot(name)=""
      >
        <div>
          {{ $t('TOTAL') }}
        </div>
      </template>
      <template
        #foot(units)=""
      >
        <div class="text-center">
          {{ calculateSalesOrdersTotalQuantity() }}
        </div>
      </template>
      <template
        #foot(price)=""
      >
        <div class="text-center">
          {{ formatNumberToCurrencyView(calculateTotalPrice()) }}
        </div>
      </template>
      <template
        #foot(discount)=""
      >
        <div class="text-center">
          {{
            formatNumberToCurrencyView(calculateTotalDiscount())
          }}
        </div>
      </template>
      <template
        #foot(subtotal)=""
      >
        <div class="text-center">
          {{
            formatNumberToCurrencyView(calculateTotalSubtotal())
          }}
        </div>
      </template>
      <template
        #foot(taxes)=""
      >
        <div class="text-center">
          {{ '$0.00' }}
        </div>
      </template>
      <template
        #foot(total)=""
      >
        <div class="text-center">
          {{
            formatNumberToCurrencyView(calculateTotal())
          }}
        </div>
      </template>
    </l-draggable-table-list-collector>
  </div>
</template>

<script>
import {
  BCol, BFormCheckbox, BFormInput, BRow, BTd, BTr, VBTooltip,
} from 'bootstrap-vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { getProps, sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'
import LDataPicker from '@/views/components/DynamicForm/components/LFlatPicker.vue'
import LDraggableTableListCollector from '@/views/components/LDraggableTableListCollector.vue'
import config from '../../create/config'

export default {
  name: 'RentalTable',
  components: {
    BTd,
    BTr,
    LDraggableTableListCollector,
    BRow,
    BCol,
    BFormInput,
    LDataPicker,
    BFormCheckbox,
    LTableListCollector,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      getTableRowIndex: null,
      discount: 10,
    }
  },
  computed: {
    orderItemsOtherCharges() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
    },
    depositRequirement() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm?.deposit_requirement
    },
    eventInformationForm() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    eventInformationRentalEnd() {
      return this.eventInformationForm.event_information_rental_end
    },
    eventInformationRentalStart() {
      return this.eventInformationForm.event_information_rental_start_dispatch
    },
    eventInformationRentalStartDispatchTime() {
      return this.eventInformationForm.event_information_rental_start_dispatch_time
    },
    eventInformationRentalEndTime() {
      return this.eventInformationForm.event_information_rental_end_time
    },
  },
  watch: {
    depositRequirement(val) {
      if (val) {
        const defaultValueForOtherCharges = {
          name: 'Deposit Requirement',
          amount: 0,
          price: val,
          discount: 0,
          tax: 0,
        }
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, {
          otherCharges: [defaultValueForOtherCharges, ...this.orderItemsOtherCharges.otherCharges?.filter(i => i.id)],
        })
      }
    },
  },
  methods: {
    navigateToProduct({ id }, e) {
      this.gotoNewPage({ name: 'home-catalog-general-info', params: { id } }, e, true)
    },
    onIncrementDecrementAction(data) {
      this.calculateSalesOrdersTotalQuantity()
      this.calculateTotalPrice()
    },

    rowHoveredHandler(rowData) {
      const { id } = rowData
      this.getTableRowIndex = id
      const { amount } = rowData
      this.$refs.incrementDecrementInputItemsRental.setValue(amount ?? 1)
      // this.rentalOrdersTotalQuantity = this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherCharges.otherCharges, 'amount')
    },

    remove({ id }) {
      this.orderItemsOtherCharges.otherCharges = this.orderItemsOtherCharges.otherCharges.filter(item => item.id !== id)
      this.salesOrdersTotalQuantity = this.calculateSalesOrdersTotalQuantity()
      this.totalPrice = this.calculateTotalPrice()
      this.totalDiscount = this.calculateTotalDiscount()
      this.totalSubtotal = this.calculateTotalSubtotal()
      this.totalTaxes = this.calculateTotalTax()
    },
    getCollectedList(list) {
      const secondList = list.map(item => ({
        ...item,
        ...list[list.length - 1].id === item.id && {
          ...item,
          amount: item.amount ?? 1,
          price: item.rates?.[0]?.rate,
          discount: this.discount,
        },
      }))

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, {
        otherCharges: secondList,
      })
      this.salesOrdersTotalQuantity = this.calculateSalesOrdersTotalQuantity()
      this.totalPrice = this.calculateTotalPrice()
      this.totalDiscount = this.calculateTotalDiscount()
      this.totalSubtotal = this.calculateTotalSubtotal()
      this.totalTaxes = this.calculateTotalTax()
    },

    calculateSalesOrdersTotalQuantity() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherCharges.otherCharges, 'amount')
    },
    calculateTotalPrice() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherCharges.otherCharges, 'price')
    },
    calculateTotalDiscount() {
      return sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherCharges.otherCharges.map(charge => ({
        c_discount: (charge.price * charge.discount) / 100,
      })), 'c_discount')
    },
    calculateTotalSubtotal() {
      return sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherCharges.otherCharges.map(charge => ({
        c_subtotal: (charge.price - (charge.price * charge.discount) / 100) * charge.amount,
      })), 'c_subtotal')
    },
    calculateTotalTax() {
      // return this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherCharges.otherCharges, 'tax')
      return 0
    },
    calculateTotal() {
      return this.totalPrice = this.calculateTotalSubtotal() - this.calculateTotalTax()
    },

    fetchedRentals() {
      return this.orderItemsOtherCharges.otherCharges
    },
    rowUnHoveredHandler() {
      this.getTableRowIndex = null
    },
  },
  setup() {
    const {
      tableColumnsOtherChanes,
      MODULE_NAME_CATALOG,
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      otherChanesTableAutoSuggestTableColumns,
      formatNumberToCurrencyView,
    } = config()

    return {
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      tableColumnsOtherChanes,
      MODULE_NAME_CATALOG,
      sumTotalAmountOfGivenArrayByProperty,
      otherChanesTableAutoSuggestTableColumns,
      formatNumberToCurrencyView,
    }
  },
}
</script>
<style lang="scss">
.cell-row-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.item-note-input {
  height: 34px;
  outline: none;
  background-color: transparent;

  &:focus {
    box-shadow: none;
  }

  .rental-sales-table .l-tableList-collector__auto-suggest input {
    background-color: #fff !important;
  }

  .rental-sales-table thead th {
    padding: 5px !important;
  }

}

.rental-sales-table-start-date {
  margin-bottom: 0;
  gap: 10px;

  label {
    font-weight: bold;
  }

  .form-control {
    height: 26px !important;
  }

  .flatpickr-input--custom-style .flatpickr-input--calendar {
    top: 5px;
  }
}

.rental-start-time {
  display: flex;

  .form-control {
    height: 26px;
    padding: 0 5px !important;
    gap: 5px;
    align-items: center;
  }

  .btn {
    padding: 0 !important;
  }
}

#rental-start-time {
  padding: 0 !important;

  svg {

  }
}
</style>
