<template>
  <div>
    <b-row>
      <b-col md="6">
        <!--        <div>-->
        <!--          <h3 class="font-weight-bolder mb-1">-->
        <!--            {{ $t(CUSTOMER_INFORMATION_FORM_TITLE) }}-->
        <!--          </h3>-->
        <!--        </div>-->
        <!--        <b-row>-->
        <div class="d-flex align-items-center">
          <component
            :is="customerInformationFields['billing_contact_id'].type"
            ref="billing_contact_ref"
            v-model="customerInformation['billing_contact_id']"
            class="flex-grow-1"
            v-bind="
              getProps(
                'billing_contact_id',
                customerInformationFields,
                true,
                'billing_contact_id',
                getParentValue
              )
            "
            @input="onChangeBillingContact($event)"
          />
          <div class="ml-2">
            <button-dashed
              class="btnBorderRevert position-relative"
              style="top: 6px"
            >
              <feather-icon
                icon="LAddButtonIcon"
                class="cursor-pointer"
                size="32"
                @click="gotoNewPage(`/settings/users/create`, $event, '_blank')"
              />
            </button-dashed>
          </div>
        </div>
        <div class="pl-1">
          <div
            v-for="field in ['billing_phone', 'billing_email', 'billing_address']"
            :key="field"
          >
            <div
              class="d-flex"
              style="gap: 12px"
            >
              <b
                class="d-inline-block"
                style="min-width: 150px"
              >
                {{ $t(customerInformationFields[field].label) }}
              </b>
              <span>{{ $t(customerInformation[field]) }}</span>
            </div>
          </div>
        </div>
        <insurance-information />
      </b-col>
      <b-col md="6">
        <div class="d-flex  flex-column justify-content-between h-100">
          <div>
            <div class="d-flex justify-content-between">
              <h3 class="font-weight-bolder mb-1">
                {{ $t(ORDER_RULES_FORM_TITLE) }}
              </h3>
              <div>
                <component
                  :is="
                    customerInformationFields['order_rules_required_security_deposit']
                      .type
                  "
                  ref="order_rules_required_security_deposit_ref"
                  v-model="customerInformation['order_rules_required_security_deposit']"
                  v-bind="
                    getProps(
                      'order_rules_required_security_deposit',
                      customerInformationFields
                    )
                  "
                />
              </div>
            </div>
            <div class="pl-1">
              <div
                v-for="field in ['price_tier', 'customer_notes']"
                :key="field"
              >
                <div
                  class="d-flex"
                  style="gap: 12px"
                >
                  <b
                    class="d-inline-block"
                    style="min-width: 150px"
                  >
                    {{ $t(customerInformationFields[field].label) }}
                  </b>
                  <span>{{ $t(customerInformation[field]) }}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex justify-content-between">
              <h3 class="font-weight-bolder mb-1">
                {{ $t(DISPATCH_FORM_TITLE) }}
              </h3>
              <div>
                <component
                  :is="
                    customerInformationFields['return_contact_is_different']
                      .type
                  "
                  ref="return_contact_is_different_ref"
                  v-model="customerInformation['return_contact_is_different']"
                  v-bind="
                    getProps(
                      'return_contact_is_different',
                      customerInformationFields
                    )
                  "
                />
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              style="gap: 8px"
            >
              <component
                :is="customerInformationFields['dispatch_contact_id'].type"
                ref="dispatch_contact_ref"
                v-model="customerInformation['dispatch_contact_id']"
                class="flex-grow-1"
                v-bind="
                  getProps(
                    'dispatch_contact_id',
                    customerInformationFields,
                    true,
                    'dispatch_contact_id',
                    getParentValue
                  )
                "
                @input="onDispatchReturnContactChange($event, 'dispatch_contact_id')"
              />
              <button-dashed
                class="btnBorderRevert position-relative opacity-50"
                style="top: 6px"
              >
                <feather-icon
                  icon="LAddButtonIcon"
                  class="cursor-pointer"
                  size="32"
                  @click="gotoNewPage(`/customers/${orderInformationFormCustomerID}/contacts`, $event, '_blank')"
                />
              </button-dashed>
            </div>
            <div class="pl-1">
              <div
                v-for="field in ['dispatch_phone', 'dispatch_email']"
                :key="field"
              >
                <div
                  class="d-flex"
                  style="gap: 12px"
                >
                  <b
                    class="d-inline-block"
                    style="min-width: 150px"
                  >
                    {{ $t(customerInformationFields[field].label) }}
                  </b>
                  <span>{{ $t(customerInformation[field]) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import SelectAccount from '@/views/main/orders/components/use-as-global/SelectAccount.vue'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import InsuranceInformation
from '@/views/main/orders/view/new-rental-sales/components/create/InsuranceInformation.vue'
import config from '../../create/config'
import UploadAttachment from './UploadAttachment.vue'

export default {
  name: 'CustomerInformation',
  components: {
    InsuranceInformation,
    ButtonDashed,
    SelectAccount,
    BFormRadio,
    BFormGroup,
    BRow,
    BCol,
    UploadAttachment,
  },
  computed: {
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    payerAccountType() {
      return this.$store.state[this.MODULE_NAME].payerAccount
    },
    idPayerAccountThirdParty() {
      return this.payerAccountType.value === this.PAYER_ACCOUNT_THIRD_PARTY
    },
    requiresPurchaseOrderReq() {
      return this.$store.state[this.MODULE_NAME].requiresPurchaseOrder
    },
    // insuranceInformation() {
    //   return this.$store.state[this.MODULE_NAME].insuranceInformationForm
    // },
  },
  methods: {
    getParentValue() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm?.customer_id
    },
    onDispatchReturnContactChange(contact, field) {
      if (field === this.RETURN_CONTACT_FIELD_NAME) {
        this.customerInformation.return_phone = getValueFromGivenObjectByKey(contact, 'cell_no')
        this.customerInformation.return_email = getValueFromGivenObjectByKey(contact, 'email')
      }
      if (field === this.DISPATCH_CONTACT_FIELD_NAME) {
        this.customerInformation.dispatch_phone = getValueFromGivenObjectByKey(contact, 'cell_no')
        this.customerInformation.dispatch_email = getValueFromGivenObjectByKey(contact, 'email')
      }
    },
    onChangeBillingContact(item) {
      const { email, phone_one } = item

      const cellNoObj = item.cell_no_object
      let formatInternational = ''
      if (cellNoObj) {
        formatInternational = JSON.parse(cellNoObj)?.formatInternational
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        // eslint-disable-next-line camelcase
        billing_phone: formatInternational || phone_one,
        billing_email: email,
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      payerAccount,
      MODULE_NAME_ORDERS,
      MODULE_NAME_ATTACHMENT,
      customerInformationFields,
      insuranceInformationFields,
      PAYER_ACCOUNT_THIRD_PARTY,
      // CUSTOMER_INFORMATION_FORM_TITLE,
      ORDER_RULES_FORM_TITLE,
      DISPATCH_FORM_TITLE,
      // INSURANCE_INFORMATION_FORM_TITLE,
      autoSuggestTableColumnsAccount,
      PAYER_ACCOUNT_THIRD_PART_CONFIRMATION_TEXT,
    } = config()
    const DISPATCH_METHOD_FIELD_NAME = 'dispatch_method'
    const RETURN_CONTACT_FIELD_NAME = 'return_contact_id'
    const DISPATCH_CONTACT_FIELD_NAME = 'dispatch_contact_id'
    return {
      getProps,
      MODULE_NAME,
      payerAccount,
      MODULE_NAME_ORDERS,
      MODULE_NAME_ATTACHMENT,
      customerInformationFields,
      // insuranceInformationFields,
      PAYER_ACCOUNT_THIRD_PARTY,
      autoSuggestTableColumnsAccount,
      // CUSTOMER_INFORMATION_FORM_TITLE,
      ORDER_RULES_FORM_TITLE,
      DISPATCH_FORM_TITLE,
      // INSURANCE_INFORMATION_FORM_TITLE,
      PAYER_ACCOUNT_THIRD_PART_CONFIRMATION_TEXT,
      DISPATCH_METHOD_FIELD_NAME,
      RETURN_CONTACT_FIELD_NAME,
      DISPATCH_CONTACT_FIELD_NAME,
      // format,
    }
  },
}
</script>
<style lang="scss">
.billing_address-border-danger {
  & textarea,
  & label {
    border-color: #6f1214;
    color: #6f1214;
  }

  & label {
    font-weight: bold;
  }
}
.order-rules-title {
  padding-left: 10px;
}
</style>
