<template>
  <div class="form-wrapper">
    <!--    <div v-if="(isRental && isEventInformationRequiredFieldsFilled) || isSale">-->
    <div>
      <div>
        <h3 class="font-weight-bolder mb-1">
          {{ $t(ORDER_ITEMS_FORM_TITLE) }}
        </h3>
      </div>
      <!--      v-if="isRental && isEventInformationRequiredFieldsFilled"-->
      <hybrid-table />
    </div>
<!--    <div>-->
<!--      <div>-->
<!--        <h3 class="font-weight-bolder mb-1">-->
<!--          {{ $t(OTHER_CHARGES_FORM_TITLE) }}-->
<!--        </h3>-->
<!--      </div>-->
<!--      <other-charges-table />-->
<!--    </div>-->
  </div>
</template>

<script>

import HybridTable from '@/views/main/catalog/view/components/HybridTable.vue'
import OtherChargesTable from './OtherChargesTable.vue'
import config from '../../create/config'

export default {
  name: 'OrderItems',
  components: {
    HybridTable,
    OtherChargesTable,
  },
  computed: {
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    isDispatchAndReturnAllRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isDispatchAndReturnAllRequiredFieldsFilled
    },
    isEventInformationRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isEventInformationRequiredFieldsFilled
    },
  },
  methods: {

  },
  setup() {
    const { ORDER_ITEMS_FORM_TITLE, MODULE_NAME, OTHER_CHARGES_FORM_TITLE } = config()

    return {
      ORDER_ITEMS_FORM_TITLE,
      OTHER_CHARGES_FORM_TITLE,
      MODULE_NAME,
    }
  },
}
</script>
