<template>
  <div>
    <customer-information class="mb-1" />
  </div>
</template>

<script>
import config from '@/views/main/orders/view/rental-sales/config'
import CustomerInformation from '../components/create/CustomerInformation.vue'

export default {
  name: 'FormPieceAppearOnCustomerSelect',
  components: {
    CustomerInformation,
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>
