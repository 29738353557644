<template>
  <div class="form-wrapper">
    <b-row>
      <!--      <b-col>-->
      <!--        <h4 class="font-weight-bolder">-->
      <!--          {{ $t(ORDER_ITEMS_RENTALS_TABLE_TITLE) }}-->
      <!--        </h4>-->
      <!--      </b-col>-->
      <b-col>
        <b-form-checkbox
          v-model="allowToPickRentalTimesByLineItem"
          @change="onAllowToPickRentalTimesByLineItem($event)"
        >
          {{ $t("Allow to pick rental times by line item ") }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <l-draggable-table-rental-sale-list-collector
      ref="lTableRef"
      :key="componentKey"
      :module-name="MODULE_NAME"
      :fetched-data="fetchedRentals()"
      :table-columns="tableColumnsHybrid"
      :auto-suggest-add-params="{type: orderItemsSaleRental.type}"
      :table-config-options="tableConfigOptions()"
      :additional-object-for-selected-item="{
        notes: '',
        isNoteOpen: false,
        rental_start: eventInformationRentalStart || new Date(),
        rental_end: eventInformationRentalEnd || new Date(),
        inheritFromOrder: true,
        event_information_rental_start_time: eventInformationRentalStartDispatchTime,
        event_information_rental_end_time: eventInformationRentalEndTime,
      }"
      :table-suggestion-configs="rentalTableAutoSuggestTableColumns"
      :has-below-actions="true"
      :has-footer="true"
      :is-drag-row="false"
      class="rental-sales-table"
      autosuggest-list-item-name="name"
      collection-of-list-item-i-d="id"
      @getCollectedList="getCollectedList"
      @rowHoveredHandler="rowHoveredHandler"
      @rowUnHoveredHandler="rowUnHoveredHandler"
      @onSelectHandler="onSelectHandler"
    >
      <template slot="after-autosuggest">
        <v-select
          v-model="orderItemsSaleRentalType['value']"
          style="width: 20%;"
          class="ml-1"
          :reduce="option => option.key"
          name="attachment_category_id"
          :options="saleRentalType"
        />
      </template>
      <template #cell(actions)="{ data }">
        <div
          :id="`td-${data.id}`"
          class="d-flex p-0"
          style="gap: 8px"
        >
          <feather-icon
            v-b-tooltip.noninteractive.click.bottom
            icon="LTrashIcon"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Remove')"
            size="30"
            style="padding: 4px"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.click.bottom
            icon="LEyeIcon"
            size="30"
            style="padding: 4px"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            :title="$t('Watch')"
            @click="navigateToProduct(data, $event)"
          />
        </div>
      </template>
      <template #below-actions="{ data }">
        <div
          v-if="allowToPickRentalTimesByLineItem"
          class="position-absolute"
          style="
            top: 55px;
            min-width: 1300px;
            width: 100%;
            z-index: 10;
            left: 0;
          "
        >
          <div>
            <b-row>
              <b-col cols="2">
                <b-form-checkbox
                  v-model="data.inheritFromOrder"
                  @change="inheritDateFormOrder"
                >
                  {{ $t("Inherit from Order") }}
                </b-form-checkbox>
              </b-col>
              <b-col cols="10">
                <div
                  class="d-flex"
                  style="gap: 10px"
                >
                  <LDataPicker
                    v-model="data.rental_start"
                    class="rental-sales-table-start-date d-flex"
                    name="rental-start"
                    :is-editable="data.inheritFromOrder"
                    :field="{
                      label: 'Rental Start',
                      options: {
                        config: {
                          'is-calendar-icon': true,
                          maxDate: endDateOfInsurance,
                        },
                      },
                    }"
                    @input="onIncrementDecrementAction(data.id)"
                  />
                  <LTimePickerInput
                    v-model="data.event_information_rental_start_time"
                    :is-editable="data.inheritFromOrder"
                    name="rental_start_time"
                    class="rental_time_picker"
                    :field="{}"
                  />
                  <LDataPicker
                    v-model="data.rental_end"
                    class="rental-sales-table-start-date d-flex"
                    name="rental-end"
                    :is-editable="data.inheritFromOrder"
                    :field="{
                      label: 'Rental End',
                      options: {
                        config: {
                          'is-calendar-icon': true,
                          maxDate: endDateOfInsurance,
                        },
                      },
                    }"
                    @input="onIncrementDecrementAction(data.id)"
                  />
                  <LTimePickerInput
                    v-model="data.event_information_rental_end_time"
                    name="rental_end_time"
                    class="rental_time_picker"
                    :is-editable="data.inheritFromOrder"
                    :field="{}"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div>
          <div
            v-if="data.isNoteOpen"
            class="position-absolute"
            style="
              bottom: 2px;
              min-width: 1000px;
              width: 100%;
              z-index: 10;
              left: 0;
            "
          >
            <b-form-input
              :ref="`item-note-input-${data.id}`"
              v-model="data.notes"
              class="item-note-input"
              style="height: 30px"
            />
          </div>
          <div
            v-if="!data.isNoteOpen && data.notes"
            class="position-absolute"
            style="
              bottom: 2px;
              min-width: 1000px;
              width: 100%;
              z-index: 10;
              left: 0;
              padding: 4px 15px;
              height: 30px;
            "
          >
            {{ data.notes }}
          </div>
        </div>
      </template>
      <template #cell(sku)="{ data }">
        <span id="sku">
          {{ data.sku }}
        </span>
        <!--        <l-tool-tip-->
        <!--          placement="bottom"-->
        <!--          target="sku"-->
        <!--        >-->
        <!--          <div class="d-flex flex-column">-->
        <!--            <div class="w-100 d-flex justify-content-end">-->
        <!--              <feather-icon-->
        <!--                icon="XIcon"-->
        <!--                class="lightIcon cursor-pointer border-dotted featherIcon"-->
        <!--                size="16"-->
        <!--              />-->
        <!--            </div>-->
        <!--            <div class="w-100 d-flex justify-content-between">-->
        <!--              <table class="w-100 mt-1">-->
        <!--                <tr>-->
        <!--                  <th />-->
        <!--                  <th />-->

        <!--                  <th>Avail.</th>-->
        <!--                  <th>Other WHs</th>-->

        <!--                </tr>-->
        <!--                <tr-->
        <!--                  v-for="label in labels"-->
        <!--                  :key="label.label.name"-->
        <!--                >-->
        <!--                  <td>{{ label.label.name }}</td>-->
        <!--                  <td><div style="width: 20px; height: 1px;" /></td>-->
        <!--                  <td>{{ label.label.value }}</td>-->
        <!--                  <td>{{ label.label.value2 }}</td>-->
        <!--                </tr>-->
        <!--              </table>-->
        <!--            </div>-->
        <!--            <div-->
        <!--              class="d-flex"-->
        <!--              style="text-decoration: underline;"-->
        <!--            >-->
        <!--              {{ $t('Alternatives') }}-->
        <!--            </div>-->
        <!--            <div class="d-flex justify-content-between">-->
        <!--              <div class="d-flex justify-content-start">-->
        <!--                {{ $t('Another Sony Camera') }}-->
        <!--              </div>-->
        <!--              <div class="d-flex">-->
        <!--                <feather-icon-->
        <!--                  icon="PlusIcon"-->
        <!--                  class="lightIcon cursor-pointer border-dotted featherIcon"-->
        <!--                  size="16"-->
        <!--                />-->
        <!--                <feather-icon-->
        <!--                  icon="LReloadIcon"-->
        <!--                  class="lightIcon cursor-pointer border-dotted featherIcon"-->
        <!--                  size="16"-->
        <!--                />-->
        <!--              </div>-->
        <!--            </div>-->

        <!--          </div>-->
        <!--        </l-tool-tip>-->
      </template>
      <template #cell(name)="{ data }">
        <div
          v-b-tooltip.noninteractive.hover
          class="cell-row-name"
          :title="data.name"
        >
          {{ data.name }}
        </div>
      </template>
      <template #cell(units)="{ data }">
        <increment-decrement-input
          ref="incrementDecrementInputItemsRental"
          class="d-flex justify-content-center"
          :item="data"
          style="height: 25px; width: 60px; margin: auto"
          item-key-name-for-amount="amount"
          :on-index-comparison="data.id === getTableRowIndex"
          @onIncrementDecrementAction="onIncrementDecrementAction(data.id)"
        >
          <!--          :is-disabled-plus="-->
          <!--          data.amount >= data.available && data.amount >= data.other_locations-->
          <!--          "-->
          <template #switch-off-action>
            <div class="switch-off-action">
              {{ data.amount }}
            </div>
          </template>
        </increment-decrement-input>
      </template>
      <template #cell(rental_price)="{ data }">
        <span class="d-flex-right"> ${{ data.rental_price }} </span>
      </template>
      <template #cell(days)="{ data }">
        <div class="d-flex-right">
          {{
            getDaysBetweenDates({
              rental_end: data.rental_end,
              rental_start: data.rental_start,
            })
          }}
        </div>
      </template>
      <template #cell(discount)="{ data }">
        <span class="d-flex-right"> {{ data.discount }}% </span>
      </template>
      <template #cell(subtotal)="{ data }">
        <span class="d-flex-right"> ${{ data.subtotal }} </span>
      </template>
      <template #cell(taxes)="{ data }">
        <span class="d-flex-right"> ${{ data.taxes }} </span>
      </template>
      <template #cell(total)="{ data }">
        <span class="d-flex-right"> ${{ data.itemTotal }} </span>
      </template>
      <!--  Footer Cells    -->
      <template #foot(name)="">
        <div>
          {{ $t("TOTAL") }}
        </div>
      </template>
      <template #foot(units)="">
        <div class="d-flex-right">
          {{ getItemKVOfProduct({ productKey: "amount" }) }}
        </div>
      </template>
      <template #foot(discount)="">
        <div class="d-flex-right">
          ${{ getItemKVOfProduct({ productKey: "discountPrice" }).toFixed(2) }}
        </div>
      </template>
      <template #foot(subtotal)="">
        <div class="d-flex-right">
          ${{ getItemKVOfProduct({ productKey: "subtotal" }).toFixed(2) }}
        </div>
      </template>
      <template #foot(taxes)="">
        <div class="d-flex-right">
          ${{ getItemKVOfProduct({ productKey: "taxes" }).toFixed(2) }}
        </div>
      </template>
      <template #foot(total)="">
        <div>
          ${{ getItemKVOfProduct({ productKey: "itemTotal" }).toFixed(2) }}
        </div>
      </template>
    </l-draggable-table-rental-sale-list-collector>
  </div>
</template>

<script>
import {
  BCol,
  BFormCheckbox,
  BFormInput,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'
import LDataPicker from '@/views/components/DynamicForm/components/LFlatPicker.vue'
import LDraggableTableRentalSaleListCollector from '@/views/main/orders/view/new-rental-sales/components/create/LDraggableTableRentalSaleListCollector.vue'
import LToolTip from '@/views/components/LToolTip.vue'
import { differenceInDays } from 'date-fns'
// import config from '../config'
import vSelect from 'vue-select'
import LTable from '@/views/components/LTable/LTable.vue'
import { th } from 'date-fns/locale'
import config from '../../../orders/view/new-rental-sales/create/config'

export default {
  name: 'HybridTable',
  components: {
    LTable,
    vSelect,
    LDraggableTableRentalSaleListCollector,
    BRow,
    BCol,
    BFormInput,
    LDataPicker,
    BFormCheckbox,
    LTableListCollector,
    LToolTip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      componentKey: 0,
      getTableRowIndex: null,
      saleRentalType: [
        {
          key: 'rent',
          label: 'Rent',
        },
        {
          key: 'sale',
          label: 'Sale',
        },
      ],
    }
  },
  computed: {
    orderItemsSaleRental() {
      return this.$store.state[this.MODULE_NAME].orderItemsSaleRentalTable
    },
    orderItemsSaleRentalType() {
      return this.$store.state[this.MODULE_NAME].orderItemsSaleRentalType
    },
    orderItemsSaleRentalTypeValue() {
      return this.$store.state[this.MODULE_NAME].orderItemsSaleRentalType.value
    },
    eventInformationForm() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    eventInformationRentalEnd() {
      return this.eventInformationForm.event_information_rental_end
    },
    endDateOfInsurance() {
      return this.eventInformationForm.coverage_end_date
    },
    eventInformationRentalStart() {
      return this.eventInformationForm.event_information_rental_start_dispatch
    },
    eventInformationRentalStartDispatchTime() {
      return this.eventInformationForm
        .event_information_rental_start_dispatch_time
    },
    eventInformationRentalEndTime() {
      return this.eventInformationForm.event_information_rental_end_time
    },
    allowToPickRentalTimesByLineItem: {
      get() {
        return this.$store.state[this.MODULE_NAME]
          .allowToPickRentalTimesByLineItem
      },
      set(bool) {
        this.$store.state[this.MODULE_NAME].allowToPickRentalTimesByLineItem = bool
      },
    },
  },
  watch: {
    orderItemsSaleRentalTypeValue() {
      this.componentKey += 1
    },
  },
  methods: {
    onSelectHandler() {
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALE_RENTAL_TABLE`, {
        products: this.orderItemsSaleRental.products,
      })
    },
    tableConfigOptions() {
      return this.orderItemsSaleRentalTypeValue === 'rent' ? {
        endpoint: `${this.MODULE_NAME}/rentalOrders`,
      } : {
        endpoint: `${this.MODULE_NAME}/saleOrders`,
      }
    },
    onIncrementDecrementAction(id) {
      const products = this.orderItemsSaleRental.products.map(item => {
        if (item.id === id) {
          if (item.amount < 1) {
            return null // Mark for removal
          }
          const days = this.getDaysBetweenDates({
            rental_end: item.rental_end,
            rental_start: item.rental_start,
          })
          const discountPrice = (
            (item.amount
              * days
              * Number(item.rental_price)
              * Number(item.discount))
            / 100
          ).toFixed(2)
          const subTotal = (
            item.amount * days * Number(item.rental_price)
            - Number(discountPrice)
          ).toFixed(2)
          return {
            ...item,
            days,
            discountPrice,
            subtotal: subTotal,
            itemTotal: subTotal,
          }
        }
        return item
      }).filter(item => item !== null)

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALE_RENTAL_TABLE`, {
        products,
      })
    },

    getCollectedList(newProductsList) {
      newProductsList = newProductsList.map(i => {
        if (newProductsList[newProductsList.length - 1].id === i.id) {
          const days = this.getDaysBetweenDates({
            rental_end: i.rental_end,
            rental_start: i.rental_start,
          })
          const subTotal = (
            days * Number(i.rental_price)
            - (Number(i.rental_price) * i.discount) / 100
          ).toFixed(2)
          const discountPrice = (
            days
            * Number(i.rental_price)
            * (i.discount / 100)
          ).toFixed(2)
          return {
            ...i,
            amount: 1,
            subtotal: subTotal,
            discountPrice,
            taxes: 0,
            itemTotal: subTotal,
          }
        }
        return i
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALE_RENTAL_TABLE`, {
        products: newProductsList,
      })
      this.onAllowToPickRentalTimesByLineItem(this.allowToPickRentalTimesByLineItem)
    },

    getDaysBetweenDates({ rental_start, rental_end }) {
      let days = 0
      if (!rental_start || !rental_end) {
        return days
      }
      return (
        (days = differenceInDays(
          new Date(rental_end),
          new Date(rental_start),
        )) + 1
      )
    },
    remove({ id, type }) {
      const filteredList = this.orderItemsSaleRental.products.filter(
        item => (item.id !== id || item.type !== type),
      )
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALE_RENTAL_TABLE`, {
        products: filteredList,
      })
    },
    getItemKVOfProduct({ productKey }) {
      return this.sumTotalAmountOfGivenArrayByProperty(
        this.orderItemsSaleRental.products,
        productKey,
      )
    },
    rowHoveredHandler(rowData) {
      const { id, amount } = rowData
      this.getTableRowIndex = id
      this.$refs.incrementDecrementInputItemsRental.setValue(amount)
    },
    rowUnHoveredHandler() {
      this.getTableRowIndex = null
    },
    fetchedRentals() {
      return this.orderItemsSaleRental.products
    },
    inheritDateFormOrder(event) {
      if (event) {
        this.setDateForOrder()
      }
    },

    setDateForOrder() {
      if (!this.eventInformationRentalStart || !this.eventInformationRentalEnd) return
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALE_RENTAL_TABLE`, {
        products: this.orderItemsSaleRental.products.map(item => ({
          ...item,
          rental_start: this.eventInformationRentalStart,
          rental_end: this.eventInformationRentalEnd,
          event_information_rental_start_time: this.eventInformationRentalStartDispatchTime,
          event_information_rental_end_time: this.eventInformationRentalEndTime,
          inheritFromOrder: true,
        })),
      })
      this.orderItemsSaleRental.products.forEach(i => {
        this.onIncrementDecrementAction(i.id)
      })
    },

    onAllowToPickRentalTimesByLineItem(event) {
      if (!event) {
        this.setDateForOrder()
      }
      this.orderItemsSaleRental.products.forEach(({ id, isNoteOpen, notes }) => {
        const tableRowTDS = document.querySelectorAll(`.td-${id}`)
        if (event) {
          if (isNoteOpen || notes) {
            tableRowTDS[0].style.height = '130px'
          } else {
            tableRowTDS[0].style.height = '90px'
          }
        } else if (isNoteOpen || notes) {
          tableRowTDS[0].style.height = '90px'
        } else {
          tableRowTDS[0].style.height = '40px'
        }
      })
    },
    navigateToProduct({ id }, e) {
      this.gotoNewPage(
        { name: 'home-catalog-general-info', params: { id } },
        e,
        true,
      )
    },
  },
  setup() {
    const {
      tableColumnsHybrid,
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      rentalTableAutoSuggestTableColumns,
      MODULE_NAME_CATALOG,
    } = config()

    return {
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      tableColumnsHybrid,
      sumTotalAmountOfGivenArrayByProperty,
      rentalTableAutoSuggestTableColumns,
      MODULE_NAME_CATALOG,
    }
  },
}
</script>
<style lang="scss">
.d-flex-right {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 2px 7px;
}

.cell-row-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.item-note-input {
  height: 34px;
  outline: none;
  background-color: transparent;

  &:focus {
    box-shadow: none;
  }

  //.rental-sales-table thead th {
  //  padding: 5px !important;
  //}
}

.rental-sales-table-start-date {
  margin-bottom: 0;
  gap: 3px;

  label {
    font-weight: bold;
  }

  //.form-control {
  //  height: 26px !important;
  //}

  //.flatpickr-input--custom-style .flatpickr-input--calendar {
  //  top: 5px;
  //}
}

.rental_time_picker {
  display: flex;

  .form-control {
    height: 26px;
    padding: 0 5px !important;
    gap: 5px;
    align-items: center;
  }

  .btn {
    padding: 0 !important;
  }
}

#rental-start-time {
  padding: 0 !important;

  #rental-start-time {
    padding: 0 !important;
  }
}
</style>
