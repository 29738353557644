<template>
  <b-button
    variant="success"
    class="font-medium-1 font-weight-bold px-3"
    :disabled="!isFormChanged"
    @click="submit"
  >
    {{ $t('Place Order') }}
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import config from '../../create/config'

export default {
  name: 'OnFormSubmitAction',
  components: {
    BButton,
  },
  props: {
    salesRentalQuoteFormRef: {
      type: Object,
      default: () => {},
    },
    // isFormChanged: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  computed: {
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    insuranceInformation() {
      return this.$store.state[this.MODULE_NAME].insuranceInformationForm
    },
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    orderItemsRental() {
      return this.$store.state[this.MODULE_NAME].orderItemsRentalTable
    },
    orderItemsSales() {
      return this.$store.state[this.MODULE_NAME].orderItemsSalesTable
    },
    isFormChanged() {
      return Boolean(Object.keys(this.orderInformation).length && Object.keys(this.customerInformation).length && Object.keys(this.dispatchAndReturn).length && Object.keys(this.eventInformation).length && this.orderItemsRental.products.length)
    },
    orderItemsOtherCharges() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
    },
    totals() {
      return this.$store.state[this.MODULE_NAME].totalsForm
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
  },
  methods: {
    submit() {
      // Getting Ref of Sales Rental Quote Form =>
      const { salesRentalQuoteForm } = this.salesRentalQuoteFormRef

      const {
        totals,
        orderInformation,
        eventInformation,
        orderItemsRental,
        orderItemsSales,
        dispatchAndReturn,
        customerInformation,
        orderItemsOtherCharges,
      } = this

      salesRentalQuoteForm.validate().then(success => {
        if (success) {
          this.$emit('onSubmitAction')

          // Mapping All Data from store
          const mappedOrderInformation = this.mappingFields(Object.keys(this.orderInformationFields), orderInformation, {
            customer_id: orderInformation.customer_id,
          })

          const mappedCustomerInformation = this.mappingFields(Object.keys(this.customerInformationFields), customerInformation, {
            billing_contact_id: customerInformation.billing_contact_id.id,
            payment_term_id: customerInformation.payment_term_id.id,
            order_placed_by_id: customerInformation.order_placed_by_id.id,
            receive_method: customerInformation.receive_method.id,
            order_rules_required_security_deposit: `${customerInformation.order_rules_required_security_deposit}` ?? 'false',
            price_tier: customerInformation.price_tier,
          })

          const mappedDispatchAndReturn = this.mappingFields(Object.keys(this.dispatchAndReturnFields), dispatchAndReturn, {
            dispatch_warehouse_id: dispatchAndReturn?.dispatch_warehouse_id?.id,
            dispatch_method: dispatchAndReturn?.dispatch_method?.id,
            return_method: dispatchAndReturn?.return_method?.id,
            return_warehouse_id: dispatchAndReturn?.return_warehouse_id?.id,
            dispatch_contact_id: dispatchAndReturn?.dispatch_contact_id?.id,
            dispatch_address_id: dispatchAndReturn?.dispatch_address_id?.id,
            return_address_id: dispatchAndReturn?.return_address_id?.id,
            return_contact_is_different: `${dispatchAndReturn?.return_contact_is_different}`,
            order_routing_order_returns_to_another_warehouse: `${dispatchAndReturn.order_routing_order_returns_to_another_warehouse}`,
            order_routing_allow_dispatch_of_items_from_warehouse_of_origin: `${dispatchAndReturn.order_routing_allow_dispatch_of_items_from_warehouse_of_origin}`,
          })

          const mappedEventInformation = this.mappingFields(Object.keys(this.eventInformationFields), eventInformation, {
            event_information_rental_end: this.mapDate(eventInformation.event_information_rental_end),
            event_information_rental_end_time: this.mapTime(eventInformation.event_information_rental_end_time),
            event_information_rental_start_dispatch: this.mapDate(eventInformation.event_information_rental_start_dispatch),
            event_information_rental_start_dispatch_time: this.mapTime(eventInformation.event_information_rental_start_dispatch_time),
          })

          const mappedOrderItemsOtherCharges = {}
          const mappedOrderItems = [].concat(orderItemsRental?.products?.map(({ id, amount }) => ({ id, amount, type: 'rent' })),
              orderItemsSales?.sales?.map(({ id, amount }) => ({ id, amount, type: 'sale' })))
          mappedOrderItemsOtherCharges.other_charges = orderItemsOtherCharges?.otherCharges?.map(({
            name, amount, discount, subtotal,
          }) => ({
            name, unit: amount, discount, subtotal,
          }))

          // total data to send =>
          const totalData = {
            ...totals,
            ...mappedOrderInformation,
            products: mappedOrderItems,
            ...mappedOrderItemsOtherCharges,
            ...mappedCustomerInformation,
            ...mappedEventInformation,
            ...mappedDispatchAndReturn,
          }
          this.sendNotification(this, totalData, `${this.MODULE_NAME}/createSalesRentalQuote`)
            .then(() => {
              this.$emit('clear')
              this.$router.push({ name: 'home-orders-rental-sales-v2' })
            })
            .catch(err => {
              this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
            })
        } else {
          scrollToError(this, salesRentalQuoteForm)
        }
      })
    },
  },
  setup() {
    const {
      mapDate,
      mapTime,
      MODULE_NAME,
      orderInformationFields,
      customerInformationFields,
      eventInformationFields,
      dispatchAndReturnFields,
    } = config()

    return {
      mapDate,
      mapTime,
      MODULE_NAME,
      orderInformationFields,
      eventInformationFields,
      dispatchAndReturnFields,
      customerInformationFields,
    }
  },
}
</script>
