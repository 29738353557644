var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper"},[_c('l-draggable-table-list-collector',{ref:"lTableRef",staticClass:"rental-sales-table",attrs:{"has-footer":true,"module-name":_vm.MODULE_NAME,"fetched-data":_vm.fetchedRentals(),"table-columns":_vm.tableColumnsOtherChanes,"additional-object-for-selected-item":{ note: '',
                                            isNoteOpen: false,
                                            rental_start: _vm.eventInformationRentalStart,
                                            rental_end: _vm.eventInformationRentalEnd,
                                            inheritFromOrder: true,
                                            event_information_rental_start_dispatch_time: _vm.eventInformationRentalStartDispatchTime,
                                            event_information_rental_start_end_time: _vm.eventInformationRentalEndTime,
    },"table-suggestion-configs":_vm.otherChanesTableAutoSuggestTableColumns,"has-below-actions":true,"table-config-options":{
      endpoint: (_vm.MODULE_NAME_CATALOG + "/nonStocksActiveList")
    }},on:{"getCollectedList":_vm.getCollectedList,"rowHoveredHandler":_vm.rowHoveredHandler,"rowUnHoveredHandler":_vm.rowUnHoveredHandler},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(ref){
    var data = ref.data;
return [(data.id)?_c('div',{staticClass:"d-flex p-0"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.click.bottom",modifiers:{"noninteractive":true,"click":true,"bottom":true}}],staticClass:"lightIcon cursor-pointer border-dotted featherIcon",staticStyle:{"padding":"4px"},attrs:{"icon":"LTrashIcon","title":_vm.$t('Remove'),"size":"30"},on:{"click":function($event){return _vm.remove({id: data.id})}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.click.bottom",modifiers:{"noninteractive":true,"click":true,"bottom":true}}],staticClass:"lightIcon cursor-pointer border-dotted featherIcon",staticStyle:{"padding":"4px"},attrs:{"icon":"LEyeIcon","size":"30","title":_vm.$t('Watch')},on:{"click":function($event){return _vm.navigateToProduct(data, $event)}}})],1):_vm._e()]}},{key:"cell(sku)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.sku ? data.sku : '-')+" ")])]}},{key:"cell(name)",fn:function(ref){
    var data = ref.data;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cell-row-name",attrs:{"title":data.name}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"cell(units)",fn:function(ref){
    var data = ref.data;
return [(data.id)?_c('increment-decrement-input',{ref:"incrementDecrementInputItemsRental",staticClass:"d-flex justify-content-center",staticStyle:{"height":"25px","width":"60px","margin":"auto"},attrs:{"item":data,"item-key-name-for-amount":"amount","on-index-comparison":data.id === _vm.getTableRowIndex,"is-disabled-minus":data.amount === 1},on:{"onIncrementDecrementAction":function($event){return _vm.onIncrementDecrementAction(data.id)}},scopedSlots:_vm._u([{key:"switch-off-action",fn:function(){return [_c('div',{staticClass:"switch-off-action"},[_vm._v(" "+_vm._s(data.amount ? data.amount : 1)+" ")])]},proxy:true}],null,true)}):_c('div',{staticStyle:{"margin-left":"47%"}},[_vm._v(" - ")])]}},{key:"cell(rates)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(data.price))+" ")])]}},{key:"cell(discount)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView((data.price * data.discount) / 100))+" ")])]}},{key:"cell(subtotal)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView((data.price - (data.price * data.discount) / 100) * data.amount))+" ")])]}},{key:"cell(taxes)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(data.tax))+" ")])]}},{key:"cell(total)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(_vm._s(_vm.formatNumberToCurrencyView(((data.price - (data.price * data.discount) / 100) * data.amount) - (data.tax === undefined ? 0 : data.tax))))])]}},{key:"foot(name)",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t('TOTAL'))+" ")])]},proxy:true},{key:"foot(units)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.calculateSalesOrdersTotalQuantity())+" ")])]},proxy:true},{key:"foot(price)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.calculateTotalPrice()))+" ")])]},proxy:true},{key:"foot(discount)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.calculateTotalDiscount()))+" ")])]},proxy:true},{key:"foot(subtotal)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.calculateTotalSubtotal()))+" ")])]},proxy:true},{key:"foot(taxes)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s('$0.00')+" ")])]},proxy:true},{key:"foot(total)",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.calculateTotal()))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }