<template>
  <div>
    <quote-status-bar
      class="mb-1"
      :bar-arr="quoteStatusBar"
    />
    <div>
      <error-alert
        :fields="{}"
        :error="onError"
      />
      <b-form>
        <validation-observer
          ref="salesRentalQuoteForm"
        >

          <order-information
            class="mb-1"
          />
          <div class="form-wrapper">
            <div
              v-b-toggle="'add-primary-contact'"
              @click="toggle('form-piece-appear-on-customer-select', 'FormPieceAppearOnCustomerSelect')"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  id="form-piece-appear-on-customer-select"
                  icon="LArrowDownIcon"
                  class="feather-chevron when-opened mr-1"
                  style="transform: rotate(-90deg)"
                />
                <h4 class="font-weight-bolder text-body m-0">
                  {{ $t(CUSTOMER_INFORMATION_FORM_TITLE) }}
                </h4>
              </div>
            </div>

            <b-collapse
              :id="'add-primary-contact'"
              ref="add-primary-contact"
              visible
            >
              <form-piece-appear-on-customer-select />
            </b-collapse>
          </div>

          <div class="form-wrapper mt-1">
            <div
              v-b-toggle="'dispatch-and-return'"
              @click="toggle('dispatch-and-return', 'DispatchAndReturn')"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  id="dispatch-and-return"
                  icon="LArrowDownIcon"
                  class="feather-chevron when-opened mr-1"
                  style="transform: rotate(-90deg)"
                />
                <h4 class="font-weight-bolder text-body m-0">
                  {{ $t(DISPATCH_FORM_TITLE) + ' & ' + $t(RETURN_FORM_TITLE) }}
                </h4>
              </div>
            </div>

            <b-collapse
              :id="'dispatch-and-return'"
              ref="dispatch-and-return"
              visible
            >
              <dispatch-and-return />
            </b-collapse>
          </div>

          <div class="form-wrapper mt-1">
            <div
              v-b-toggle="'event-information'"
              @click="toggle('event-information', 'EventInformation')"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  id="event-information"
                  icon="LArrowDownIcon"
                  class="feather-chevron when-opened mr-1"
                  style="transform: rotate(-90deg)"
                />
                <h4 class="font-weight-bolder text-body m-0">
                  {{ $t(EVENT_INFORMATION_FORM_TITLE) }}
                </h4>
              </div>
            </div>

            <b-collapse
              :id="'event-information'"
              ref="event-information"
              visible
            >
              <event-information />
            </b-collapse>
          </div>
          <order-items class="mt-1" />
          <div class="form-wrapper mt-1">
            <div
              v-b-toggle="'other-charges-table'"
              @click="toggle('other-charges-table', 'OtherChargesTable')"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  id="other-charges-table"
                  icon="LArrowDownIcon"
                  class="feather-chevron when-opened mr-1"
                  style="transform: rotate(-90deg)"
                />
                <h4 class="font-weight-bolder text-body m-0">
                  {{ $t(OTHER_CHARGES_FORM_TITLE) }}
                </h4>
              </div>
            </div>
            <b-collapse
              :id="'other-charges-table'"
              ref="other-charges-table"
              visible
            >

              <other-charges-table />
            </b-collapse>
          </div>

          <totals class="mt-1" />

        </validation-observer>
      </b-form>
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            v-if="true"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="$router.push({name: 'home-orders-rental-sales-list-v2'})"
          >
            {{ $t('Back to List') }}
          </b-button>
          <b-button
            v-else
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
            />
            {{ $t('Cancel') }}
          </b-button>
        </div>
        <div
          class="d-flex"
          style="gap: 8px"
        >
          <b-button
            disabled
            variant="secondary"
            class="font-medium-1 font-weight-bold px-3"
          >
            <feather-icon icon="LPrintIcon" />
            {{ $t('Print') }}
          </b-button>
          <b-button
            variant="secondary"
            class="font-medium-1 font-weight-bold px-3"
            @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT)"
          >
            {{ $t(FORM_BUTTON_TITLE_SAVE_AS_DRAFT) }}
          </b-button>
          <b-button
            variant="danger"
            class="font-medium-1 font-weight-bold px-3"
            :disabled="!isFormChangedAsPlaceOrder"
            @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS)"
          >
            {{ $t('Hold Assets') }}
          </b-button>
          <b-button
            variant="secondary"
            class="font-medium-1 font-weight-bold px-3"
            :disabled="!isFormChangedAsPlaceOrder"
            @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL)"
          >
            {{ $t('Send by Email') }}
          </b-button>
          <b-button
            variant="success"
            class="font-medium-1 font-weight-bold px-3"
            :disabled="!isFormChangedAsPlaceOrder"
            @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER)"
          >
            {{ $t('Place Order') }}
          </b-button>
        </div>
      </div>
    </portal>
    <b-s-warning-modal
      ref="confirm-modal"
      @onNextTick="onNextTick"
    />
  </div>
</template>

<script>
// Components
import {
  BButton, BCollapse, BForm, VBToggle,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import config from '@/views/main/orders/view/new-rental-sales/config'
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import DispatchAndReturn from '@/views/main/orders/view/new-rental-sales/components/create/DispatchAndReturn.vue'
import InsuranceInformation from '@/views/main/orders/view/new-rental-sales/components/create/InsuranceInformation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomerInformation
from '@/views/main/orders/view/new-rental-sales/components/create/CustomerInformation.vue'
import OtherChargesTable
from '@/views/main/orders/view/new-rental-sales/components/create/OtherChargesTable.vue'
import Totals from '../components/Totals.vue'
import QuoteStatusBar from '../../../components/use-as-global/QuoteStatusBar.vue'
import OrderItems from '../components/create/OrderItems.vue'
import EventInformation from '../components/create/EventInformation.vue'
import OrderInformation from '../components/create/OrderInformation.vue'
import FormPieceAppearOnCustomerSelect from './FormPieceAppearOnCustomerSelect.vue'
import OnFormSubmitAction from '../components/create/OnFormSubmitAction.vue'
import pgConfig from './config'

export default {
  name: 'Form',
  components: {
    OtherChargesTable,
    CustomerInformation,
    BCollapse,
    InsuranceInformation,
    DispatchAndReturn,
    BForm,
    Totals,
    BButton,
    OrderItems,
    ErrorAlert,
    QuoteStatusBar,
    OrderInformation,
    EventInformation,
    ValidationObserver,
    OnFormSubmitAction,
    FormPieceAppearOnCustomerSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      onSubmit: false,
    }
  },
  created() {
    this.$store.dispatch('listModule/warehousesAddress')
  },
  computed: {
    ...mapGetters('rental-sales', ['getTotalDataOfForms']),
    isFormChangedAsPlaceOrder() {
      return Boolean(Object.keys(this.orderInformation)?.length && Object.keys(this.customerInformation)?.length && Object.keys(this.dispatchAndReturn)?.length && Object.keys(this.eventInformation)?.length)
    },
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    isDispatchAndReturnAllRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isDispatchAndReturnAllRequiredFieldsFilled
    },
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    isEventInformationRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isEventInformationRequiredFieldsFilled
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    rentalSalesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].rentalSalesFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.getTotalDataOfForms) !== this.rentalSalesFormClone
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    orderItemsSales() {
      return this.$store.state[this.MODULE_NAME].orderItemsSalesTable
    },
  },
  methods: {
    toggle(id, collapseToggler) {
      this[collapseToggler] = !this[collapseToggler]
      if (this[collapseToggler]) {
        document.getElementById(id).style.transform = 'rotate(0)'
        document.getElementById(id).style.transition = 'all 0.2s ease'
      } else {
        document.getElementById(id).style.transform = 'rotate(-90deg)'
      }
    },
    onSubmitAction() {
      this.onSubmit = true
    },
    redirectToRFQSend(id, action) {
      // if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
      //   if (id) {
      //     this.$router.push({
      //       name: 'hold-quote-information',
      //       params: { id },
      //     })
      //   } else {
      //     this.$router.push({
      //       name: 'create-rental-sales-quote',
      //     })
      //   }
      // }
      this.$router.push({
        name: 'home-orders-rental-sales-v2',
      })
    },
    submit(action) {
      this.error = {}
      this.onSubmit = true
      const getTotal = this.getTotalDataOfForms
      if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
        setTimeout(() => {
          this.$refs.salesRentalQuoteForm.reset()
        }, 0)
      }
      setTimeout(() => {
        let filteredObj = Object.fromEntries(
          Object.entries({ action, ...getTotal }).filter(([key, value]) => value !== undefined),
        )

        filteredObj = {
          ...filteredObj,
          action,
          billing_contact_id: filteredObj?.billing_contact_id?.id,
          dispatch_warehouse_id: filteredObj?.dispatch_warehouse_id?.id,
          dispatch_method: filteredObj?.dispatch_method?.id,
          return_method: filteredObj?.return_method?.id,
          return_warehouse_id: filteredObj?.return_warehouse_id?.id,
          dispatch_contact_id: filteredObj?.dispatch_contact_id?.id,
          dispatch_address_id: filteredObj?.dispatch_address_id?.id,
          return_address_id: filteredObj?.return_address_id?.id,
          order_placed_by_id: filteredObj?.order_placed_by_id?.id,
          return_contact_id: filteredObj?.return_contact_id?.id,
          receive_method: filteredObj?.receive_method?.id,
          other_charges: filteredObj?.otherCharges?.filter(i => i?.id)?.map(i => ({
            ...i,
            unit: i.amount,
            subtotal: (Number(i.price || 0) - (Number(i.price || 0) * i.discount) / 100) * i.amount,
            tax: i.price,
          })),
          payment_term_id: filteredObj?.payment_term_id?.id,
          event_information_rental_end_time: filteredObj?.event_information_rental_end_time?.length && filteredObj?.event_information_rental_end_time?.length >= 8
            ? filteredObj?.event_information_rental_end_time?.substring(0, 5) : filteredObj?.event_information_rental_end_time,
          event_information_rental_start_dispatch_time: filteredObj?.event_information_rental_start_dispatch_time?.length && filteredObj?.event_information_rental_start_dispatch_time?.length >= 8
            ? filteredObj?.event_information_rental_start_dispatch_time?.substring(0, 5) : filteredObj?.event_information_rental_start_dispatch_time,
          event_information_rental_end: filteredObj?.event_information_rental_end && format(filteredObj?.event_information_rental_end, 'yyyy-MM-dd'),
          event_information_rental_start_dispatch: filteredObj?.event_information_rental_start_dispatch && format(filteredObj?.event_information_rental_start_dispatch, 'yyyy-MM-dd'),
          products: [...filteredObj.products, ...this.orderItemsSales.products]?.map(product => ({
            id: product?.id,
            amount: product?.amount,
            type: product.type,
            notes: product?.notes,
            rental_start: product?.rental_start ? format(product?.rental_start, 'yyyy-MM-dd HH:mm') : null,
            rental_end: product?.rental_end ? format(product?.rental_end, 'yyyy-MM-dd HH:mm') : null,
          })),
          payer_account: 'renter',
        }

        delete filteredObj.otherCharges

        this.sendNotification(this, filteredObj, `${this.MODULE_NAME}/create`)
          .then(({ data }) => {
            const { id } = data.data
            this.redirectToRFQSend(id, action)
            this.clear()
          })
          .catch(err => {
            this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
            this.error = err.response?.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: this.error.message,
              },
            })
          })
      }, 1)
    },

    submitByEmail() {
      this.onSubmitAction()
    },
    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) {
        this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
      }
    },
    onNextTick() {
      this.onClear()
    },
  },
  setup() {
    const {
      getProps,
      MODULE_NAME,
      quoteStatusBar,
      MODULE_NAME_CLONE,
      storeMutationsList,
      ORDER_INFORMATION_FORM_TITLE,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
    } = config()
    const {
      CUSTOMER_INFORMATION_FORM_TITLE,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      EVENT_INFORMATION_FORM_TITLE,
      OTHER_CHARGES_FORM_TITLE,
    } = pgConfig()
    return {
      getProps,
      MODULE_NAME,
      quoteStatusBar,
      MODULE_NAME_CLONE,
      storeMutationsList,
      ORDER_INFORMATION_FORM_TITLE,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
      CUSTOMER_INFORMATION_FORM_TITLE,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      EVENT_INFORMATION_FORM_TITLE,
      OTHER_CHARGES_FORM_TITLE,
    }
  },
}
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0
}
</style>
